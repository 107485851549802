import React from "react";

function HomeOutlined() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 11.9999V11.4999V11.4999C2 10.4267 2 9.89012 2.10417 9.38781C2.26984 8.58896 2.62847 7.84279 3.14877 7.21439C3.47594 6.81925 3.89496 6.48404 4.73299 5.81361L7.00244 3.99805C8.78603 2.57118 9.67783 1.85774 10.6668 1.58413C11.5392 1.34279 12.4608 1.34279 13.3332 1.58413C14.3222 1.85774 15.214 2.57118 16.9976 3.99805L18.9976 5.59805C20.1001 6.48011 20.6514 6.92115 21.0483 7.46857C21.3998 7.95353 21.6613 8.49771 21.8204 9.07517C22 9.72701 22 10.433 22 11.845V14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22H7.50006C7.0357 22 6.80353 22 6.60766 21.9846C4.15581 21.7916 2.20841 19.8442 2.01542 17.3923C2 17.1965 2 16.9643 2 16.4999V16.4999V15.9999M15 19V15C15 14.0681 15 13.6022 14.8478 13.2346C14.6448 12.7446 14.2554 12.3552 13.7654 12.1522C13.3978 12 12.9319 12 12 12V12C11.0681 12 10.6022 12 10.2346 12.1522C9.74458 12.3552 9.35523 12.7446 9.15224 13.2346C9 13.6022 9 14.0681 9 15V22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HomeOutlined;
