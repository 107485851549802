import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Row } from "reactstrap";
import progression_bar from "../../assets/images/progression-bar.png";
import logo_maaia from "../../assets/images/maaia-logo-violet.png";
import { withTranslation } from "react-i18next";
const ProgressionBar = (props) => {
  const dataMap = new Map();
  const [formattedDataList, setformattedDataList] = useState([]);

  const theStatistics = props.statistics;

  // const data = {
  //   labels: ["2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020"],
  //   datasets: [
  //     {
  //       label: "",
  //       lineTension: 0.2,
  //       borderColor: ["#FF7049"],
  //       borderWidth: 3,
  //       fill: false,
  //       pointBackgroundColor: "#ffffff",
  //       pointBorderColor: "#FF7049",
  //       data: [20, 80, 40, 110, 60, 140, 80, 110],
  //     },
  //     {
  //       label: "",
  //       lineTension: 0.2,
  //       backgroundColor: "rgba(235, 239, 242, 0)",
  //       borderColor: ["#563BFF"],
  //       borderWidth: 3,
  //       fill: false,
  //       pointBackgroundColor: "#ffffff",
  //       pointBorderColor: "#563BFF",
  //       data: [80, 140, 100, 170, 120, 200, 140, 170],
  //     },
  //   ],
  // };
  const [data, setData] = useState(null);
  var option = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            // max: 200,
            // min: 0,
            stepSize: 50,
            // zeroLineColor: "#7b919e",
            // borderDash: [3, 3],
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  function formatGraphDate(dateString, period, locale = "en") {
    const date = new Date(dateString);

    switch (period) {
      case "H4":
        return new Intl.DateTimeFormat(locale, { year: "numeric", month: "2-digit", day: "2-digit" }).format(date); // Formate en jour (DD/MM/YYYY ou autre selon locale)

      case "WEEK":
        // Trouver le lundi de la semaine
        const dayOfWeek = date.getUTCDay();
        const diff = date.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Ajuster pour que la semaine commence le lundi
        const monday = new Date(date);
        monday.setUTCDate(diff);
        return `Week of ${new Intl.DateTimeFormat(locale, { year: "numeric", month: "2-digit", day: "2-digit" }).format(monday)}`; // Formate en semaine

      case "MONTH":
        return new Intl.DateTimeFormat(locale, { year: "numeric", month: "2-digit" }).format(date); // Formate en mois (MM/YYYY ou autre selon locale)

      default:
        throw new Error("Invalid period. Please choose between 'H4', 'WEEK', or 'MONTH'.");
    }
  }

  const getData = (theStatistics) => {
    const percent_mail_count_list = theStatistics.map((statistic) => {
      return {
        period: statistic.period,
        date: statistic.$updatedAt,
        percent_treated_mail_count: statistic.percent_treated_mail_count,
        percent_untreated_mail_count: statistic.percent_untreated_mail_count,
      };
    });
    console.log({ percent_mail_count_list });

    percent_mail_count_list.forEach((element) => {
      dataMap.set(formatGraphDate(element.date, element.period, props.i18n.language), {
        percent_treated_mail_count: Math.round(element.percent_treated_mail_count),
        percent_untreated_mail_count: Math.round(element.percent_untreated_mail_count),
      });
    });
    console.log("dataMap is", dataMap);

    console.log("dataMap.keys() is", Array.from(dataMap.keys()));
    console.log("dataMap.values() is", Array.from(dataMap.values()));

    const data = {
      labels: Array.from(dataMap.keys()),
      datasets: [
        {
          label: "% Mails Répondus",
          lineTension: 0.2,
          borderColor: ["#FF7049"],
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "#FF7049",
          data: Array.from(dataMap.keys()).map((element) => dataMap.get(element).percent_treated_mail_count),
        },
        {
          label: "% Mails Non Traités",
          lineTension: 0.2,
          backgroundColor: "rgba(235, 239, 242, 0)",
          borderColor: ["#563BFF"],
          borderWidth: 3,
          fill: false,
          pointBackgroundColor: "#ffffff",
          pointBorderColor: "#563BFF",
          data: Array.from(dataMap.keys()).map((element) => dataMap.get(element).percent_untreated_mail_count),
        },
      ],
    };

    console.log("dataForGraphic is", data);
    setformattedDataList(data);
  };
  useEffect(() => {
    getData(theStatistics);
  }, [theStatistics]);

  return formattedDataList && formattedDataList.labels?.length > 0 ? (
    <div
      style={{
        position: "relative",
        display: "grid",
        gridTemplateRows: "minmax(0,3fr)",
        height: "86%",
      }}>
      <Line data={formattedDataList} options={option} />
    </div>
  ) : (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        backgroundImage: "url(" + progression_bar + ")",
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}>
      <div className="font-primary font-size-22 color-black mt-5">{props.t("En attente de données")}</div>
      <img src={logo_maaia} height={40} />
    </div>
  );
};

export default withTranslation()(ProgressionBar);
