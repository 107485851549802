import axios from "axios";
import config from "../config";
import { Client, Account, ID, Databases, Query, Functions, ExecutionMethod } from "appwrite";

// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  },
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

export const maaia_dashboard_url = "https://manage.maaia.co";
// export const maaia_dashboard_url = "http://localhost:5000";
export const calculator_id = "qYpjy2MJq9BKJRdQ1E4z";
export const calculator_id_upgrade = "BxqgWRVDXAldJdLlEjp3";
export const calculator_id_downgrade = "dQWg2NRJK0lVm5PEjOa0";
// export const calculator_id = "zP2VwELmdr2vD0X6YonM"; // localhost
export const stripe_payment_return_url = `${maaia_dashboard_url}/dashboard`;
export const prestashop_payment_return_url = `${maaia_dashboard_url}/prestashop-oauth`;

const appwriteClient = new Client();
appwriteClient.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const getLoggedinUser = async () => {
  const user = localStorage.getItem("authUser");
  // const user = await checkSession();
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

async function getCompanyInfos() {
  const client = new Client();
  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const databases = new Databases(client, "default");
  // const user_data = localStorage.getItem("authUser");
  const company_id = localStorage.getItem("companyId");
  if (!company_id) return undefined;

  const promise = databases.getDocument("default", "client_infos", company_id);

  return await promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      return undefined;
    },
  );
}
async function getCompanyInfosPromise() {
  const client = new Client();
  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const databases = new Databases(client, "default");
  // const user_data = localStorage.getItem("authUser");
  const company_id = localStorage.getItem("companyId");
  if (!company_id) return undefined;

  return databases.getDocument("default", "client_infos", company_id);
}
export function getMailboxErrorMessage(error) {
  console.log({ getMailboxErrorMessage: error });

  if (error.includes("Name does not resolve")) {
    return "mailbox_error_server_name";
  } else if (error.includes("Authentication failed") || error.includes("AUTHENTICATIONFAILED")) {
    return "mailbox_error_auth";
  } else {
    return "system_error";
  }
}

async function updateCompanyTokenUsage(the_token_usage_in, the_token_usage_out) {
  const company_infos = await getCompanyInfos();

  if (!company_infos) {
    console.log("Couldn't get company infos for token_usage update");
    return undefined;
  }
  const client = new Client();
  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
  const databases = new Databases(client, "default");

  try {
    const response = await databases.updateDocument("default", "client_infos", company_infos.$id, {
      token_usage_in: the_token_usage_in + company_infos.token_usage_in,
      token_usage_out: the_token_usage_out + company_infos.token_usage_out,
    });
    if (response) {
      console.log("Company token_usage updated");
    }
    return response;
  } catch (error) {
    console.log("Failled fetching Company token_usage: ", error);
    return undefined;
  }
}

async function getUser() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = account.get();

  return promise
    .then(
      function (response) {
        // console.log("user is", response);
        localStorage.setItem("user", JSON.stringify(response));
        return response;
      },
      // function (error) {
      //   console.log('error on session check', error);
      //   return undefined;
      // }
    )
    .catch(function (error) {
      console.log("error on session check", error);
      return undefined;
    });
}
async function getUserPromise() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return account.get();
}
async function getEmailSession() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = account.getSession("current");

  promise.then(
    function (response) {
      // console.log('Success on session check at login page');
      // console.log(response);
    },
    function (error) {
      // console.log("error on session check", error);
    },
  );
}

async function createEmailAccount(email, password) {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return account.create(ID.unique(), email, password);

  /*
  await promise.then(
    function (response) {
      console.log('Success email session created');
      console.log(response);
      localStorage.setItem('authUser', JSON.stringify(response));
      return response;
    },
    function (error) {
      console.log('error on email session create', error);

      let message;
      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        case 404:
          message = 'Sorry! the data you are looking for could not be found';
          break;
        default:
          message = error.message || error;
      }

      return message;
    }
  );
  */
}

async function createStatisticsFunction(company_id) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return await functions.createExecution("maaia_statistic_function_manager", JSON.stringify({ company_id: company_id }), true);
}

async function createAiTextImprovementFunctionExecution(data) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return await functions.createExecution("ai_email_reply", JSON.stringify(data), false, "/text-generation", ExecutionMethod.POST);
}

async function createAIResponseExecution(data) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return await functions.createExecution("ai_email_reply", data, false, "/email-reply", ExecutionMethod.POST);
}

async function createAgentFunctionExecution(company_id, agent_id) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return await functions.createExecution(
    "ai_agent_manager",
    JSON.stringify({ company_id, agent_id }),
    false,
    "/create-function",
    ExecutionMethod.POST,
  );
}

async function deleteMailerAgentFunction(company_id, agent_id) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return await functions.createExecution(
    "ai_agent_manager",
    JSON.stringify({ company_id, agent_id }),
    false,
    "/delete-function",
    ExecutionMethod.POST,
  );
}

async function createEmployeeFunction(employee) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return functions.createExecution("6697978e00002df0812c", JSON.stringify({ employee: employee }), false);
}

async function createEmailSessionPromise(email, password) {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return account.createEmailPasswordSession(email, password);
}

async function createEmailSession(email, password) {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = account.createEmailPasswordSession(email, password);

  await promise.then(
    function (response) {
      // console.log("Success email session created");
      // console.log(response);
      // localStorage.setItem('authUser', JSON.stringify(response));
      return response;
    },
    function (error) {
      // console.log("error on email session create", error);
      return null;
    },
  );
}

async function updateUserPrefs(thePrefs) {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = account.updatePrefs(thePrefs);

  await promise.then(
    function (response) {
      // console.log("Success user prefs updated");
      // console.log(response);
      return response;
    },
    function (error) {
      //  console.log("error on user prefs update", error);
      return null;
    },
  );
}

async function updateUserPrefsPromise(thePrefs) {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return account.updatePrefs(thePrefs);
}

// async function fetchMailsList(company_id, box, currentPage, pageSize) {
//   // setShowReadEmailView(false);

// const apiUrl = `https://mailbox.maaia.co/${/*box && box === "MAIA.Sent" ? "get-all-mails" : */ "get-threads"}`;
//   // const apiUrl = "http://localhost:55000/get-threads";
//   // const apiUrl = `https://localhost:55000/${box && box === "MAIA.Sent" ? "get-all-mails" : "get-threads"}`;
//   // console.log("fetchMailsList launched", currentPage);
//   return axios.get(apiUrl, {
//     params: {
//       company_id: company_id,
//       folder: box ? (box === "INBOX.MAIA.Sent" ? "MAIA.Sent" : box) : "INBOX",
//       page: currentPage ?? 1,
//       limit: pageSize ?? 20,
//       day_since: box !== "INBOX" ? 70 : 70,
//     },
//   });
// }

async function fetchMailsList(company_id, box, currentPage, pageSize) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      company_id: company_id,
      folder: box ? (box === "INBOX.MAIA.Sent" ? "MAIA.Sent" : box) : "INBOX",
      page: currentPage ?? 1,
      limit: pageSize ?? 20,
      day_since: box !== "INBOX" ? 130 : 130,
    }),
    false,
    "/get-threads",
    ExecutionMethod.GET,
  );
  console.log("str_response /get-threads", response);

  try {
    if (response && response.responseBody && response.responseStatusCode === 200) {
      let str_response = response.responseBody;
      str_response = JSON.parse(str_response);
      return str_response;
    }
  } catch (e) {
    return response.responseBody;
  }
}

// async function fetchSingleMail(message_id, company_id, box) {
//   const apiUrl = "https://mailbox.maaia.co/get-single-mail";

//   return axios.get(apiUrl, {
//     params: {
//       message_id: message_id,
//       company_id: company_id,
//       folder: box ? box : "INBOX",
//     },
//   });
// }

async function fetchSingleMail(message_id, company_id, box) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      message_id: message_id,
      company_id: company_id,
      folder: box ? box : "INBOX",
    }),
    false,
    "/get-single-mail",
    ExecutionMethod.GET,
  );
  console.log("str_response /get-single-mail", str_response);
  return JSON.parse(str_response.responseBody);
}

async function fetchSearchedMails(search, company_id, box, currentPage, pageSize) {
  const apiUrl = "https://mailbox.maaia.co/search-mails";

  const response = axios.get(apiUrl, {
    params: {
      search: search,
      company_id: company_id,
      folder: box,
      page: currentPage,
      limit: pageSize,
      day_since: 60,
    },
  });
  return response;
}

async function shopifyOauth(shop) {
  const apiUrl = "https://mailer-ai.semecloud.tech/shopify/shopify-oauth";
  // return await axios.get(apiUrl, {
  //   mode: "no-cors",
  //   params: {
  //     shop,
  //   },
  // });

  try {
    const response = await axios.get(apiUrl, {
      // headers: {
      //   "X-Shopify-Access-Token": shopify_api_key,
      // },
      params: {
        shop,
      },
    });

    if (response.status === 304 || response.status === 303 || response.status === 200) {
      const the_data = response.data;
      // console.log("response is", response);
      // console.log("the_data is", the_data);
      // console.log("redirectUrl is", the_data.auth_url);
      const redirectUrl = the_data.auth_url;
      // Effectuer la redirection si une URL de redirection est présente
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        // console.log("no redirectUrl");
      }
    } else {
      // console.log("Error is", response);
      window.location.href = response.auth_url;
    }
  } catch (error) {
    // console.log("Error", error);
  }
}

async function fetchAgentLatestVersion(company_id, agent_id) {
  const apiUrl = "https://mailer-ai.semecloud.tech/mailer-version";
  const response = await axios.get(apiUrl, {
    params: {
      company_id,
      agent_id,
    },
  });
  return response;
}

async function fetchAgentInfos(company_id, agent_id) {
  const apiUrl = "https://mailer-ai.semecloud.tech/inspect-agent";
  const response = await axios.get(apiUrl, {
    params: {
      company_id,
      agent_id,
    },
  });
  return response;
}

async function UpgradeAgent(company_id, agent_id) {
  const apiUrl = "https://mailer-ai.semecloud.tech/upgrade-agent";
  const response = await axios.post(apiUrl, {
    company_id,
    agent_id,
  });
  return response;
}

export async function createStripeCheckoutSession(region, return_url, line_items) {
  const apiUrl = "http://localhost:4242/stripe-create-checkout-session";
  try {
    const response = await axios.post(
      apiUrl,
      JSON.stringify({
        stripe_region: region,
        return_url,
        line_items,
      }),
    );
    return response;
  } catch (error) {
    console.log("Error createStripeCheckoutSession" + error);

    return undefined;
  }
}

export async function createStripeCustomer(stripe_region, email, name) {
  // const apiUrl = "http://localhost:4242/stripe-create-customer";
  // try {
  //   const response = await axios.post(
  //     apiUrl,
  //     JSON.stringify({
  //       stripe_region,
  //       email,
  //       name,
  //     }),
  //   );
  //   console.log({ createStripeCustomer: response });

  //   return response;
  // } catch (error) {
  //   console.log("Error createStripeCheckoutSession" + error);

  //   return undefined;
  // }

  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      email,
      name,
    }),
    false,
    "/stripe-create-customer",
    ExecutionMethod.POST,
  );

  console.log({ createStripeCustomer: response });

  if (response && response.responseBody && response.responseStatusCode === 200) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error createStripeCustomer", { createStripeCustomer: response });
  }
}

export async function createStripeSubscripion(stripe_region, return_url, items, customerId, metadata, coupon_id) {
  // const apiUrl = "http://localhost:4242/stripe-create-subscription";
  // try {
  //   const response = await axios.post(
  //     apiUrl,
  //     JSON.stringify({
  //       stripe_region,
  //       return_url,
  //       items,
  //       customerId,
  //     }),
  //   );
  //   return response;
  // } catch (error) {
  //   console.log("Error createStripeCheckoutSession" + error);

  //   return undefined;
  // }

  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      return_url,
      items,
      customerId,
      metadata,
      coupon_id,
    }),
    false,
    "/stripe-create-subscription",
    ExecutionMethod.POST,
  );

  console.log({ createStripeSubscripion: response });

  if (response && response.responseBody && response.responseStatusCode === 200) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error createStripeSubscripion", { createStripeSubscripion: response });
    return undefined;
  }
}

export async function updateStripeSubscripion(stripe_region, subscription_id, items, metadata) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      subscription_id,
      items,
      metadata,
    }),
    false,
    "/stripe-update-subscription",
    ExecutionMethod.POST,
  );

  console.log({ updateStripeSubscripion: response });

  if (response && response.responseBody && response.responseStatusCode === 200) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error updateStripeSubscripion", { updateStripeSubscripion: response });
    return undefined;
  }
}

export async function retrieveStripeUpComingInvoice(stripe_region, customer_id, subscription_id, items) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      subscription_id,
      items,
      customer_id,
    }),
    false,
    "/stripe-upcoming-invoice-retrieve",
    ExecutionMethod.GET,
  );

  console.log({ retrieveStripeUpComingInvoice: response });

  if (response && response.responseBody && response.responseStatusCode === 200) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error retrieveStripeUpComingInvoice", { retrieveStripeUpComingInvoice: response });
    return undefined;
  }
}

export async function createStripePaymentEntent(region, plan_id) {
  const apiUrl = "http://localhost:4242/stripe-create-payment-intent";
  try {
    const response = await axios.post(apiUrl, JSON.stringify({ items: [{ id: "maaia_infinity" }], stripe_region: region }));
    console.log({ createStripePaymentEntent: response });

    return response;
  } catch (error) {
    console.log("Error createStripeCheckoutSession" + error);

    return undefined;
  }
}

// async function shopifyOauthCallback(code, hmac, host, shop, state, timestamp) {
//   const apiUrl = `http://localhost:50001/auth/callback?code=${code}&hmac=${hmac}&host=${host}&shop=${shop}&state=${state}&timestamp=${timestamp}`;

//   try {
//     const response = await fetch(apiUrl, {
//       method: "GET",
//       mode: "no-cors",
//     });
//     const redirectUrl = response.url;
//     // Effectuer la redirection si une URL de redirection est présente
//     if (redirectUrl) {
//       window.location.href = redirectUrl;
//     } else {
//       // console.log("no redirectUrl");
//     }
//   } catch (error) {
//     // console.error("Error fetching data:", error);
//     throw error;
//   }
// }

// async function fetchFoldersList(id) {
//   // onBoxesLoading(true);
//   const apiUrl = "https://mailbox.maaia.co/get-boxes";
//   // const apiUrl = "http://localhost:55000/get-boxes";
//   return axios.get(apiUrl, {
//     params: {
//       company_id: id,
//     },
//   });
// }

async function fetchFoldersList(id) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
  try {
    const str_response = await functions.createExecution(
      "function_box_manager_api_py",
      JSON.stringify({
        company_id: id,
      }),
      false,
      "/get-boxes",
      ExecutionMethod.GET,
    );
    console.log("str_response /get-boxes", str_response);

    if (str_response && str_response.responseBody && str_response.responseStatusCode === 200 && !str_response.responseBody.includes("error")) {
      return JSON.parse(str_response.responseBody);
    } else {
      console.log("Error fetchFoldersList", { searchShopifyOrder: str_response });
      return undefined;
    }
  } catch (error) {
    console.log("Error fetchFoldersList", error);
  }
}

async function copyMailPromise(message_id, the_folder, destination_folder) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      company_id,
      destination_folder: destination_folder,
      folder: the_folder,
      messageId: message_id,
    }),
    false,
    "/copy-mail",
    ExecutionMethod.POST,
  );
  console.log("str_response /copy-mail", str_response);
  return str_response.responseBody;
}

async function moveMailPromise(message_id, folder, destination_folder) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      company_id: company_id,
      destination_folder: destination_folder,
      folder: folder,
      messageId: message_id,
    }),
    false,
    "/move-mail",
    ExecutionMethod.POST,
  );
  console.log("str_response /move-mail", str_response);
  return str_response.responseBody;
}

async function deleteMailPromise(message_id, destination_box) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      company_id: company_id,
      folder: destination_box,
      messageId: message_id,
    }),
    false,
    "/delete-mail",
    ExecutionMethod.POST,
  );
  console.log("str_response /delete-mail", str_response);
  return str_response.responseBody;
}

async function sendMailPromise(messageToReply, text, message_to_reply_id) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      to: messageToReply.body.from.value[0].address,
      to_name: messageToReply.body.from.value[0].name,
      subject: messageToReply.body.subject,
      text: text,
      company_id: company_id,
      message_to_reply_references: messageToReply.headers.references,
      message_to_reply_id,
      message_to_reply: messageToReply.body.html ? messageToReply.body.html : messageToReply.body.textAsHtml,
    }),
    false,
    "/send-mail",
    ExecutionMethod.POST,
  );
  console.log("str_response /send-mail", str_response);
  return str_response.responseBody;
}

async function createBoxPromise(folder) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      company_id: company_id,
      folder: folder,
    }),
    false,
    "/create-boxe",
    ExecutionMethod.PUT,
  );
  console.log("str_response /create-boxe", str_response);
  return str_response.responseBody;
}

async function createAllMaaiaBoxes() {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      company_id: company_id,
    }),
    false,
    "/create-maaia-boxes",
    ExecutionMethod.PUT,
  );
  console.log("str_response /create-maaia-boxes", str_response);
  return str_response;
}

async function translateWithDeepLPromise(text, target_lang) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const str_response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      text,
      target_lang,
    }),
    false,
    "/deepl/v2/translate",
    ExecutionMethod.GET,
  );
  console.log("str_response /deepl/v2/translate", str_response);
  return str_response.responseBody;
}

async function logoutUserSession() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  return account.deleteSessions();
}

async function createLog(data) {
  try {
    const client = new Client();
    const databases = new Databases(client, "default");
    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
    const response = await databases.createDocument("default", "logs", ID.unique(), data);

    // console.log("Log created ", response); // Success
    return response;
  } catch (error) {
    // console.log("Error creating Log ", error); // Gestion de l'erreur
    return null;
  }
}

async function createFeedBack(data) {
  try {
    const client = new Client();
    const databases = new Databases(client, "default");
    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
    const response = await databases.createDocument("default", "feedback", ID.unique(), data);

    // console.log("Feedback created ", response); // Success
    return response;
  } catch (error) {
    // console.log("Error creating Feedback ", error); // Gestion de l'erreur
    return null;
  }
}

async function getEmployeeLink(userId) {
  const client = new Client();
  const databases = new Databases(client, "default");

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = databases.listDocuments("default", "employee_link", [Query.equal("employee_id", userId)]);

  return promise.then(
    async function (response) {
      // console.log("Sidebar.js: Employee link is ", response.documents);
      return response.documents;
    },
    function (error) {
      // console.log("Error creating Employee link", error); // Failure
      return null;
    },
  );
}
async function getItemFromChargeBee(limit) {
  // const apiUrl = `http://localhost:35000/chargebee/item-prices`;
  const apiUrl = `https://mailer-ai.semecloud.tech/chargebee/item-prices`;
  const params = { limit: limit };
  return axios
    .get(apiUrl, { params })
    .then((response) => {
      console.log({ response });
      return response;
    })
    .catch((error) => {
      console.log("Failed copying msg", error);
      return [];
    });
}

async function createSubscriptionChargeBee(id, email, first_name, last_name, item_price_id, local) {
  // const apiUrl = `http://localhost:35000/chargebee/create-subscription`;
  const apiUrl = `https://mailer-ai.semecloud.tech/chargebee/create-subscription`;

  return axios
    .post(
      apiUrl,
      JSON.stringify({
        id,
        email,
        first_name,
        last_name,
        item_price_id,
        local,
        redirect_url: maaia_dashboard_url,
        cancel_url: maaia_dashboard_url,
      }),
    )
    .then((response) => {
      console.log({ subscription_url: response });
      return response;
    })
    .catch((error) => {
      console.log({ subscription_error: error });
      return undefined;
    });
}

async function updateSubscriptionChargeBee(id, email, first_name, last_name, item_price_id, new_item_price_id, local) {
  // const apiUrl = `http://localhost:35000/chargebee/update-subscription`;
  const apiUrl = `https://mailer-ai.semecloud.tech/chargebee/update-subscription`;

  return axios
    .post(
      apiUrl,
      JSON.stringify({
        id,
        email,
        first_name,
        last_name,
        item_price_id,
        new_item_price_id,
        // token_id,
        local,
        redirect_url: maaia_dashboard_url,
        cancel_url: maaia_dashboard_url,
      }),
    )
    .then((response) => {
      console.log({ subscription_url: response });
      return response;
    })
    .catch((error) => {
      console.log({ subscription_error: error });
      return undefined;
    });
}

async function getFeaturesFromChargeBee(limit, entity_id) {
  // const apiUrl = `http://localhost:35000/chargebee/entitlements`;
  const apiUrl = `https://mailer-ai.semecloud.tech/chargebee/entitlements`;
  const params = { limit: limit, entity_id: entity_id };
  return axios
    .get(apiUrl, { params })
    .then((response) => {
      console.log({ response });
      return response;
    })
    .catch((error) => {
      console.log("Failed copying msg", error);
      return [];
    });
}

async function getCustomerFromChargeBee(limit, email) {
  // const apiUrl = `http://localhost:35000/chargebee/customers`;
  const apiUrl = `https://mailer-ai.semecloud.tech/chargebee/customers`;
  const params = { limit: limit, email: email };
  return axios
    .get(apiUrl, { params })
    .then((response) => {
      console.log({ response });
      return response;
    })
    .catch((error) => {
      console.log("Failed copying msg", error);
      return [];
    });
}

async function getPaymentSourcesFromChargeBee(limit, customer_id, reference_id) {
  // const apiUrl = `http://localhost:35000/chargebee/payment-sources`;
  const apiUrl = `https://mailer-ai.semecloud.tech/chargebee/payment-sources`;

  const params = { limit: limit, customer_id: customer_id, reference_id: reference_id };
  return axios
    .get(apiUrl, { params })
    .then((response) => {
      console.log({ response });
      return response;
    })
    .catch((error) => {
      console.log("Failed copying msg", error);
      return [];
    });
}

async function getSubscriptionFromChargeBee(limit, customer_id) {
  // const apiUrl = `http://localhost:35000/chargebee/subscriptions`;
  const apiUrl = `https://mailer-ai.semecloud.tech/chargebee/subscriptions`;

  const params = { limit: limit, customer_id: customer_id };
  return axios
    .get(apiUrl, { params })
    .then((response) => {
      console.log({ response });
      return response;
    })
    .catch((error) => {
      console.log("Failed copying msg", error);
      return [];
    });
}

async function getInvoicesFromChargeBee(limit, customer_id) {
  // const apiUrl = `http://localhost:35000/chargebee/invoices`;
  const apiUrl = `https://mailer-ai.semecloud.tech/chargebee/invoices`;
  const params = { limit: limit, customer_id: customer_id };
  return axios
    .get(apiUrl, { params })
    .then((response) => {
      console.log({ response });
      return response;
    })
    .catch((error) => {
      console.log("Failed copying msg", error);
      return [];
    });
}

async function getStripeSubscriptionPromise(subscription_id, stripe_region) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const data = {
    subscription_id,
    stripe_region,
  };
  return functions.createExecution("third_party_api", JSON.stringify(data), false, "/stripe-subscriptions-get", ExecutionMethod.GET);
}
async function getStripeProductPromise(product_id, stripe_region) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const data = {
    product_id,
    stripe_region,
  };
  return functions.createExecution("third_party_api", JSON.stringify(data), false, "/stripe-products-get", ExecutionMethod.GET);
}
async function getStripePaymentMethodPromise(payment_method_id, stripe_region) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const data = {
    payment_method_id,
    stripe_region,
  };
  return functions.createExecution("third_party_api", JSON.stringify(data), false, "/stripe-payment-method-get", ExecutionMethod.GET);
}
async function getStripeInvoicesListPromise(customer_id, limit, stripe_region) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const data = {
    customer_id,
    stripe_region,
    limit,
  };
  return functions.createExecution("third_party_api", JSON.stringify(data), false, "/stripe-invoices-list", ExecutionMethod.GET);
}
async function getStripeProductsListPromise(limit) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const data = {
    limit,
  };
  return functions.createExecution("third_party_api", JSON.stringify(data), false, "/stripe-products-list", ExecutionMethod.GET);
}

export async function getStripeSubscription(subscription_id, stripe_region, need_product, minimal) {
  try {
    const exec_response = await getStripeSubscriptionPromise(subscription_id, stripe_region);
    console.log("exec_response Stripe subscription get", exec_response);
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log("Execution succeeded:", JSON.parse(exec_response.responseBody));
      const responseBody = JSON.parse(exec_response.responseBody).subscription;
      const item = responseBody.items.data.find(
        (item) =>
          item.price.id === "price_1Pno66Jcj0cebeM8snut8tYC" ||
          item.price.id === "price_1Pno6RJcj0cebeM8Kb05R8uj" ||
          item.price.id === "price_1Pno51B6CkBqvsv3xGNqsD85" ||
          item.price.id === "price_1Pno5RB6CkBqvsv30aS5IQNH",
      );
      const mailpack_item = responseBody.items.data.find(
        (item) => item.price.id === "price_1Po5d2Jcj0cebeM8fdeCqALq" || item.price.id === "price_1Po5t4B6CkBqvsv3j1nOcbDE",
      );
      const product_id = item.price.product;
      const sub_item_id = item.id;
      const mail_pack_sub_item_id = mailpack_item.id;
      const price_id = item.price.id;
      const number_of_emails =
        responseBody.items.data
          .filter((item) => {
            if (item.price.id === "price_1Po5d2Jcj0cebeM8fdeCqALq" || item.price.id === "price_1Po5t4B6CkBqvsv3j1nOcbDE") {
              return item.quantity;
            }
            return 0;
          })
          .reduce((partialSum, item) => partialSum + item.quantity, 0) * 100;

      let subscription_product;
      if (need_product) {
        subscription_product = await getStripeProduct(product_id, stripe_region);
      }

      if (minimal) {
        return {
          id: responseBody.id,
          name: subscription_product ? subscription_product.name : "",
          sub_item_id,
          mail_pack_sub_item_id,
          price_id,
        };
      }
      return {
        id: responseBody.id,
        name: subscription_product ? subscription_product.name : "",
        plan_active_id: product_id,
        next_billed_at: responseBody.current_period_end * 1000,
        trialing: responseBody.trial_start ? true : false,
        number_of_emails,
        active: responseBody.status === "active",
        default_payment_method: responseBody.default_payment_method,
      };
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function getStripeProduct(product_id, stripe_region) {
  try {
    const exec_response = await getStripeProductPromise(product_id, stripe_region);
    console.log("exec_response Stripe product get", exec_response);
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log("Execution succeeded:", JSON.parse(exec_response.responseBody));
      return JSON.parse(exec_response.responseBody).product;
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function getStripePaymentMethod(payment_method_id, stripe_region) {
  try {
    const exec_response = await getStripePaymentMethodPromise(payment_method_id, stripe_region);
    console.log("exec_response Stripe Payment Method get", exec_response);
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log("Execution succeeded:", JSON.parse(exec_response.responseBody));
      return JSON.parse(exec_response.responseBody).payment_method;
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function getStripeInvoicesList(customer_id, limit, stripe_region) {
  try {
    const exec_response = await getStripeInvoicesListPromise(customer_id, limit, stripe_region);
    console.log("exec_response Stripe Invoices List get", exec_response);
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log("Execution succeeded:", JSON.parse(exec_response.responseBody));

      const products_map = await getStripeProductsList(undefined);
      // console.log({ products_map });

      if (!products_map) {
        return null;
      }
      let invoiceList = JSON.parse(exec_response.responseBody).invoices.data.map((invoice) => {
        const product_id = invoice.lines.data.find((data) => data.quantity === 1).plan.product;
        return {
          id: invoice.id,
          date: invoice.created,
          name: products_map[product_id],
          invoice_pdf_url: invoice.invoice_pdf,
          product_id,
        };
      });

      // console.log({ invoiceList });

      return invoiceList;
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function getStripeProductsList(limit) {
  try {
    const exec_response = await getStripeProductsListPromise(limit);
    console.log("exec_response Stripe Product List get", exec_response);
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log("Execution succeeded:", JSON.parse(exec_response.responseBody));
      const products_array = JSON.parse(exec_response.responseBody).products.data;
      let products_map = {};
      for (let index = 0; index < products_array.length; index++) {
        products_map = {
          ...products_map,
          [products_array[index].id]: products_array[index].name,
        };
      }
      return products_map;
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function getStripeSubscriptionPlanPriceId(subscription_id, stripe_region) {
  try {
    const exec_response = await getStripeSubscriptionPromise(subscription_id, stripe_region);
    console.log("exec_response Stripe subscription get", exec_response);
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log("Execution succeeded:", JSON.parse(exec_response.responseBody));
      const responseBody = JSON.parse(exec_response.responseBody).subscription;
      const price_id = responseBody.items.data.find((item) => item.quantity === 1).price.id;

      return price_id;
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function applyStripeDiscountCode(stripe_region, promo_code) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      promo_code,
    }),
    false,
    "/stripe-apply-discount-code",
    ExecutionMethod.POST,
  );

  console.log({ applyStripeDiscountCode: response });

  if (response && response.responseBody && response.responseStatusCode === 200) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error applyStripeDiscountCode", { applyStripeDiscountCode: response });
  }
}

export async function checkAIAgentFunction(agent_id, company_id) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  try {
    const response = await functions.listExecutions(`${company_id}_${agent_id}`);

    console.log({ checkAIAgentFunction: response });

    if (response && response.responseBody && response.responseStatusCode === 200) {
      return JSON.parse(response.responseBody);
    } else {
      console.log("Error checkAIAgentFunction", { checkAIAgentFunction: response });
      return null;
    }
  } catch (e) {
    console.log("Error checkAIAgentFunction", { checkAIAgentFunction: e });
    return { error_code: e.code };
  }
}

export async function cancelStripeSubscription(stripe_region, subscription_id) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      subscription_id,
    }),
    false,
    "/stripe-cancel-subscription",
    ExecutionMethod.POST,
  );

  console.log({ cancelStripeSubscription: response });

  if (response && response.responseBody && response.responseStatusCode === 200) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error cancelStripeSubscription", { cancelStripeSubscription: response });
    return null;
  }
}

async function getUserPrefs() {
  const account = new Account(appwriteClient);
  const promise = account.getPrefs();
  return promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      // console.log(error);
      return undefined;
    },
  );
}
/*
async function checkSession() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');

  const promise = account.getSession('current');

  promise.then(
    function (response) {
      console.log('Success on session check');
      console.log(response);
      return response;
    },
    function (error) {
      console.log('error on session check', error);
      return null;
    }
  );
}
*/

async function getUserCompanyInfos(userId) {
  // console.log({ userId });

  const client = new Client();
  const databases = new Databases(client, "default");

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
  const promise = databases.getDocument("default", "client_infos", userId);
  return promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      // console.log("Error on getUserCompanyInfos", error, error.code);
      return null;
    },
  );
}

async function createPwRecovery(email) {
  const client = new Client();
  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");
  const account = new Account(client);
  return account.createRecovery(email, "https://manage.maaia.co/auth-resetpw");
}

async function fetchStatistics(company_id, period, start_date, end_date) {
  const client = new Client();
  const databases = new Databases(client, "default");

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const promise = databases.listDocuments("default", "6650725659de3b15cb3a", [
    Query.equal("company_id", company_id),
    Query.equal("period", period),
    Query.between("$updatedAt", start_date, end_date),
    Query.orderDesc("$updatedAt"),
  ]);
  return promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      console.log("Error on fetchStatistics", error, error.code);
      return null;
    },
  );
}

async function searchShopifyOrder(order_number, order_field, shopify_api_key, shopify_store_url) {
  // const apiUrl = `http://ai.maaia.co:50000/shopify/search-order`;

  // return axios.get(apiUrl, {
  //   params: {
  //     search,
  //     order_field,
  //     shopify_api_key,
  //     shopify_store_url,
  //   },
  // });
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      order_number,
      order_field,
      shopify_api_key,
      shopify_store_url,
    }),
    false,
    "/shopify/search-order",
    ExecutionMethod.GET,
  );

  console.log({ searchShopifyOrder: response });

  if (response && response.responseBody && response.responseStatusCode === 200) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error searchShopifyOrder", { searchShopifyOrder: response });
    return undefined;
  }
}

export async function getCollections(shopify_api_key, shopify_store_url) {
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      shopify_api_key,
      shopify_store_url,
    }),
    false,
    "/shopify/collections-get",
    ExecutionMethod.GET,
  );

  console.log({ getCollections: response });

  if (response && response.responseBody && response.responseStatusCode === 200) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error getCollections", { getCollections: response });
    return null;
  }
}

export const appwriteDatabase = new Databases(appwriteClient, "default");

export const getCustomerChargeBee = async (limit, email) => {
  try {
    const response = await getCustomerFromChargeBee(limit, email);
    return response;
  } catch (error) {
    return null;
  }
};

export const getSubscriptionsChargeBee = async (limit, customer_id) => {
  try {
    const response = await getSubscriptionFromChargeBee(limit, customer_id);
    return response;
  } catch (error) {
    return null;
  }
};

export const retreiveActiveSubscriptionPlan = (activeSubscription, listPlans) => {
  const plan_id = activeSubscription.subscription_items.find((item) => item.item_type === "plan").item_price_id;
  const active_subscription_plan = listPlans.find((plan) => plan.id === plan_id);
  return active_subscription_plan;
};

export const getprimaryPaymentSourceChargeBee = async (limit, customer_id, reference_id) => {
  try {
    const response = await getPaymentSourcesFromChargeBee(limit, customer_id, reference_id);
    if (response && response.length > 0) {
      console.log({ primaryPaymentSource: response[0] });
      return response[0];
    }
  } catch (error) {
    return null;
  }
};

export {
  APIClient,
  setAuthorization,
  getLoggedinUser,
  createEmailAccount,
  updateUserPrefs,
  updateUserPrefsPromise,
  logoutUserSession,
  createEmailSession,
  createEmailSessionPromise,
  createStatisticsFunction,
  createEmployeeFunction,
  isUserAuthenticated,
  getUser,
  getUserPromise,
  createLog,
  getEmployeeLink,
  getItemFromChargeBee,
  getFeaturesFromChargeBee,
  getSubscriptionFromChargeBee,
  getCustomerFromChargeBee,
  getPaymentSourcesFromChargeBee,
  getInvoicesFromChargeBee,
  fetchMailsList,
  fetchSingleMail,
  fetchSearchedMails,
  getUserPrefs,
  getCompanyInfos,
  getCompanyInfosPromise,
  getUserCompanyInfos,
  fetchFoldersList,
  copyMailPromise,
  moveMailPromise,
  deleteMailPromise,
  sendMailPromise,
  createBoxPromise,
  translateWithDeepLPromise,
  createFeedBack,
  shopifyOauth,
  fetchAgentLatestVersion,
  fetchAgentInfos,
  UpgradeAgent,
  updateCompanyTokenUsage,
  createPwRecovery,
  fetchStatistics,
  searchShopifyOrder,
  createSubscriptionChargeBee as subscriptionChargeBee,
  updateSubscriptionChargeBee,
  createAIResponseExecution,
  createAiTextImprovementFunctionExecution,
  createAgentFunctionExecution,
  createAllMaaiaBoxes,
  getStripeSubscriptionPromise,
};
