import React from "react";

function BellOutlined({ width, height, style, className, strokeWidth, children }) {
  return (
    <div style={{ position: "relative" }}>
      <svg width={width} height={height} style={style} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.99999 19.5455C8.65859 21.0273 10.3784 22 12 22C13.6216 22 15.3414 21.0273 16 19.5455M18 11.1902C18 12.0239 18.3538 12.8185 18.9735 13.3762L20.0814 14.3733C21.404 15.5636 20.982 17.7338 19.3099 18.3419L19.0989 18.4186C14.5132 20.0861 9.4868 20.0861 4.90108 18.4186L4.69008 18.3419C3.01796 17.7338 2.59603 15.5636 3.91853 14.3733L5.02646 13.3762C5.64614 12.8185 5.99999 12.0239 5.99999 11.1902V8C5.99999 4.68629 8.68628 2 12 2C14.973 2 17.441 4.16229 17.917 7"
          stroke="currentColor"
          strokeWidth={strokeWidth || "1.5"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {children}
    </div>
  );
}

export default BellOutlined;
