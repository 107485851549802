import React from "react";

function UsersOutlined(props) {
  const { width, height, className } = props;
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 22H4.2C2.43269 22 1 20.5673 1 18.8V18.8C1 16.149 3.14903 14 5.8 14H10.2C12.851 14 15 16.149 15 18.8V18.8C15 20.5673 13.5673 22 11.8 22H10.5M17 22H19.8C21.5673 22 23 20.5673 23 18.8V18.8C23 16.149 20.851 14 18.2 14H17M15 10C17.2091 10 19 8.20914 19 6C19 3.79086 17.2091 2 15 2M12 6C12 8.20914 10.2091 10 8 10C5.79086 10 4 8.20914 4 6C4 3.79086 5.79086 2 8 2C10.2091 2 12 3.79086 12 6Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UsersOutlined;
