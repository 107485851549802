import {
  EMPTY_SUBSCRIPTION,
  FETCH_USER_SUBSCRIPTION,
  SUBSCRIPTION_CREDITS,
  USER_PLAN_FREE_TRANSLATION_LIMIT,
  USER_PLAN_STATUS,
  USER_PLAN_SUCCESS,
} from "./actionTypes";

export const applyUserSubscriptionSuccess = (userPlan) => {
  return {
    type: USER_PLAN_SUCCESS,
    payload: userPlan,
  };
};

export const setUserSubscriptionState = (isUserPlanActive) => {
  return {
    type: USER_PLAN_STATUS,
    payload: isUserPlanActive,
  };
};

export const setFreeTranslationLimitState = (freeTranslationLimit) => {
  return {
    type: USER_PLAN_FREE_TRANSLATION_LIMIT,
    payload: freeTranslationLimit,
  };
};

export const setSubscriptionCreditsState = (subscriptionCredits) => {
  return {
    type: SUBSCRIPTION_CREDITS,
    payload: subscriptionCredits,
  };
};

export const fetchUserSubscription = () => {
  return {
    type: FETCH_USER_SUBSCRIPTION,
    payload: {},
  };
};

export const emptySubscriptionStorage = () => {
  return {
    type: EMPTY_SUBSCRIPTION,
    payload: {},
  };
};
