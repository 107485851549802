import React from "react";

function EmailOutlined() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 9.5V9V9C22 8.07099 22 7.60649 21.9384 7.21783C21.5996 5.07837 19.9216 3.40042 17.7822 3.06156C17.3935 3 16.929 3 16 3H10C7.19974 3 5.79961 3 4.73005 3.54497C3.78924 4.02433 3.02433 4.78924 2.54497 5.73005C2 6.79961 2 8.19974 2 11V13C2 15.8003 2 17.2004 2.54497 18.27C3.02433 19.2108 3.78924 19.9757 4.73005 20.455C5.79961 21 7.19974 21 10 21H16C16.929 21 17.3935 21 17.7822 20.9384C19.9216 20.5996 21.5996 18.9216 21.9384 16.7822C22 16.3935 22 15.929 22 15V15V14.5M18 8L17 8.66667L16.4376 9.0416C14.8338 10.1108 14.0319 10.6454 13.1652 10.8531C12.3992 11.0366 11.6008 11.0366 10.8348 10.8531C9.96808 10.6454 9.16618 10.1108 7.5624 9.0416L7 8.66667L6 8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default EmailOutlined;
