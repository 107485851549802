import React, { useState } from "react";

import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import checkImgSrc from "../../assets/images/sucesscheck.png";
import logo_maaia from "../../assets/images/logo/maaia_logo_1.png";

import { Container, Row, Col, Card, CardBody, FormFeedback, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { createPwRecovery } from "../../helpers/api_helper";
import { withTranslation } from "react-i18next";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

const RecoverPassword = (props) => {
  document.title = "Recover Password | IA - Smart Assistant";

  const [message, setMessage] = useState({ type: null, alert: null });
  const [loading, setLoading] = useState(null);
  const onPwRecoverLoading = (val) => {
    setLoading(val);
  };

  const validation = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email").required("Please enter your email"),
    }),
    onSubmit: (values) => {
      handleCreatePwRecovery(values.email);
    },
  });

  const handleCreatePwRecovery = async (email) => {
    onPwRecoverLoading(true);
    try {
      const response = await createPwRecovery(email);
      if (response) {
        setMessage({ type: "success", alert: "" });
        onPwRecoverLoading(false);
      }
    } catch (error) {
      onPwRecoverLoading(false);
      switch (error.type) {
        case "user_not_found":
          setMessage({ type: "error", alert: props.t("user_not_found") });
          break;
        default:
          setMessage({ type: "error", alert: props.t("Echec lors de l'envoi du mail.") });
          break;
      }
    }
  };

  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="account-pages pt-5">
          <Container>
            <Row className="w-100 justify-content-center">
              <Col lg={12} md={6} xl={6}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <img
                            src={logo_maaia}
                            height={54}
                            style={{
                              marginTop: "3vh",
                            }}
                          />
                        </Link>
                      </div>
                      <h4 className="font-size-16 font-primary text-muted mt-2 text-center">{props.t("Réinitialisation de votre mot de passe")}</h4>
                      <Row className="d-flex justify-content-center">
                        <Col></Col>
                        <Row>
                          <Col md={12}>
                            {!loading && message.type === "success" && (
                              <div className="alert alert-success text-center font-primary">
                                <b>{props.t("Email envoyé avec succès !")}</b> <br />
                                {props.t("Veuillez consulter votre boîte de réception.")}
                              </div>
                            )}
                            {!loading && message.type === "error" && (
                              <div className="alert alert-danger text-center font-primary">{message.alert}</div>
                            )}
                          </Col>
                        </Row>
                      </Row>
                      <Row className="d-flex justify-content-center">
                        {message.type && (
                          <Col className="d-flex justify-content-center">
                            <img style={{ display: loading || message.type === "error" ? "none" : "block" }} src={checkImgSrc} alt="" />
                            {loading && <span className="spinner-border spinner-border-xxl me-2 mt-4" role="status" aria-hidden="true"></span>}
                          </Col>
                        )}
                        <Row>
                          <Col md={12}>
                            {message.type !== "success" && (
                              <>
                                <div className="alert alert-warning alert-dismissible text-center font-primary">
                                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                  {props.t("Entrez votre")} <b>{props.t("adresse mail")}</b> {props.t("et les instructions vous seront envoyées !")}
                                </div>
                                <div className="mt-4">
                                  <Label className="form-label font-primary" htmlFor="useremail">
                                    {props.t("Email")}
                                  </Label>
                                  <Input
                                    id="useremail"
                                    name="email"
                                    className="form-control font-primary form-control-recoverpw"
                                    placeholder={props.t("Entrez votre adresse mail")}
                                    type="email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={validation.touched.email && validation.errors.email ? true : false}
                                  />
                                  {validation.touched.email && validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                      <div className="font-primary" style={{ fontSize: "14px" }}>
                                        {props.t(validation.errors.email)}
                                      </div>
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div className="d-grid mt-4">
                                  <button
                                    className="btn btn-primary waves-effect waves-light font-primary"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      borderRadius: "10px",
                                      fontSize: "16px",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      lineHeight: "29.152px",
                                    }}
                                    onClick={() => {
                                      validation.handleSubmit();
                                    }}>
                                    {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />}{" "}
                                    {props.t("Envoyer l'e-mail")}
                                  </button>
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {/* <p className="simple-text font-primary">
                    Vous n'avez pas de compte ?{" "}
                    <Link to="/register" className="fw-medium text-primary simple-text">
                      {" "}
                      Inscrivez-vous{" "}
                    </Link>{" "}
                  </p> */}
                  <p className="simple-text font-primary">© {new Date().getFullYear()} Maaia</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(RecoverPassword);
