import React from "react";

export function IconParkSolidRobotOne(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <defs>
        <mask id="ipSRobotOne0">
          <g fill="none">
            <rect width="30" height="24" x="9" y="18" fill="#fff" stroke="#fff" strokeWidth="4" rx="2"></rect>
            <circle cx="17" cy="26" r="2" fill="#000"></circle>
            <circle cx="31" cy="26" r="2" fill="#000"></circle>
            <path fill="#000" d="M20 32a2 2 0 1 0 0 4zm8 4a2 2 0 1 0 0-4zm-8 0h8v-4h-8z"></path>
            <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M24 10v8M4 26v8m40-8v8"></path>
            <circle cx="24" cy="8" r="2" stroke="#fff" strokeWidth="4"></circle>
          </g>
        </mask>
      </defs>
      <path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSRobotOne0)"></path>
    </svg>
  );
}
export default IconParkSolidRobotOne;
