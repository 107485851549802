import React, { useState, useEffect } from "react";
import GaugeChart from "react-gauge-chart";
import {
  getUserCompanyInfos,
  getCustomerChargeBee,
  getSubscriptionsChargeBee,
  getItemFromChargeBee,
  getprimaryPaymentSourceChargeBee,
  createAgentFunctionExecution,
  getStripeSubscription,
  getCompanyInfos,
  checkAIAgentFunction,
} from "../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsumedCreditRedux } from "../../store/actions";

const chartStyle = {
  width: "100%",
  //   height: "44px",
};

const LiveGaugeChart = (props) => {
  //  const [company_infos, setCompanyInfos] = useState(undefined);
  const dispatch = useDispatch();

  const [subscriptions, setSubscriptions] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [loadingChargebeeInfos, setLoadingChargebeeInfos] = useState(false);
  const [emailCount, setEmailCount] = useState(0);

  const { consumed_email_count, company_infos, number_of_emails } = useSelector((state) => {
    return {
      number_of_emails: state.subscription.subscriptionCredits,
      consumed_email_count: state.GeneralStateRedux.consumed_email_count,
      company_infos: state.GeneralStateRedux.company_infos,
    };
  });

  const [loadingStripeInfos, setLoadingStripeInfos] = useState(false);

  // console.log({ company_infos });
  // console.log({ credit });

  async function checkAIAgent() {
    const agentsList = company_infos.agents;

    if (agentsList && agentsList.length > 0) {
      const agents = agentsList.map((element) => JSON.parse(element));
      const result = await checkAIAgentFunction(agents[0].id, company_infos.$id);
      console.log("checkAIAgentFunction result", result);
      if (result && result.error_code && result.error_code === 404) {
        console.log("checkAIAgentFunction no function found", result.error_code);
        await createAgentFunctionExecution(company_infos.$id, agents[0].id);
      }
    }
  }

  const handleUserCompanyInfo = async () => {
    try {
      const userId = localStorage.getItem("companyId");
      if (userId) {
        const response = await getCompanyInfos();
        if (response) {
          if (!company_infos) {
            // setCompanyInfos(response);
          } else if (response.consumed_email_count !== company_infos.consumed_email_count) {
            //  setCompanyInfos(response);
          }
        }
      }
    } catch (error) {}
  };
  // Simulate live data updates

  // const getEmailCount = (subscriptionData) => {
  //   const emailItem = subscriptionData.subscription_items.find((item) => item.item_price_id === "nb-emails-EUR-Monthly");
  //   console.log({ emailItem });
  //   setEmailCount(emailItem ? emailItem.quantity : 0);
  // };

  // const handleFetchChargeBeeInfos = async () => {
  //   console.log("gaugechart handleFetchChargeBeeInfos");
  //   setLoadingChargebeeInfos(true);
  //   try {
  //     //
  //     const customers = await getCustomerChargeBee(undefined, props.userInfos.email);
  //     console.log({ customers });
  //     if (customers.length) {
  //       const subscriptions = await getSubscriptionsChargeBee(undefined, customers[0].id);
  //       console.log({ gaugechart_subscriptions: subscriptions });
  //       setSubscriptions(subscriptions);
  //       const active_subscription = subscriptions.find((subscription) => subscription.status === "active");
  //       console.log({ gaugechart_activeSubscription: active_subscription });
  //       setActiveSubscription(active_subscription);
  //       getEmailCount(active_subscription);
  //     }
  //     setLoadingChargebeeInfos(false);
  //   } catch (error) {
  //     setLoadingChargebeeInfos(false);
  //     // console.log({ error });
  //   }
  // };

  useEffect(() => {
    dispatch(fetchConsumedCreditRedux());
  }, [props.userInfos, emailCount]);

  useEffect(() => {
    if (company_infos && number_of_emails) {
      setEmailCount(number_of_emails);
      checkAIAgent();
    }
  }, [company_infos, number_of_emails]);

  return (
    <div className="d-flex align-items-center">
      <div style={{ width: "77px", height: "44px" }}>
        <GaugeChart
          id="gauge-chart1"
          style={chartStyle}
          marginInPercent={0}
          cornerRadius={0}
          nrOfLevels={6}
          // percent={value}
          percent={
            /* credit && */ emailCount && emailCount > 0 && company_infos
              ? consumed_email_count >= emailCount
                ? 0
                : (emailCount - consumed_email_count) / emailCount
              : 0
          }
          arcPadding={0.03}
          arcWidth={0.23}
          colors={["#FF7B69", "#FFB293", "#C8DFFF", "#C4CDFF", "#B497FF", "#6C67FF"]}
          needleColor={"#151D48"}
          needleBaseColor={"#151D48"}
          hideText={true}
          // arcsLength={[0.3, 0.17, 0.17, 0.17, 0.17, 0.3]}
          animate={false}
          // animDelay={500}
          // animateDuration={3000}
          // formatTextValue={null}
        />
      </div>
      <div className="gauge-stats-container ms-3">
        <span
          className="gauge-stats__number"
          style={{
            color: "#9DA4B1",
            fontFamily: "Poppins, sans-serif",
            fontSize: " 14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}>
          {/* credit && */ emailCount && emailCount > 0 ? (consumed_email_count >= emailCount ? "0" : emailCount - consumed_email_count) : "0"}
          {"/"}
        </span>
        <span
          className="gauge-stats__total"
          style={{
            color: "#151D48",
            fontFamily: "Poppins, sans-serif",
            fontSize: " 14px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}>
          {/* credit && */ emailCount && emailCount > 0 ? emailCount : 0}
        </span>
      </div>
    </div>
  );
};

export default LiveGaugeChart;
