import React from "react";

function CheckGestalt({ width, height, style, className }) {
  return (
    <svg width={width} height={height} style={style} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 13L9 18L15 11.4545M20 6L17.25 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default CheckGestalt;
