import React, { useState, useEffect } from "react";
import { getCustomerChargeBee, getSubscriptionsChargeBee, getItemFromChargeBee, getprimaryPaymentSourceChargeBee } from "../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import setSeconds from "date-fns/setSeconds";
import { withTranslation } from "react-i18next";
import { Client, Account, Databases, ID, Query } from "appwrite";

const chartStyle = {
  width: "100%",
  //   height: "44px",
};

const Checkout = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);
  const onLoadingChanged = (val) => {
    setLoading(val);
  };

  const [show_finish_button, showFinishButton] = useState(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const email_count = urlParams.get("e");
  const plan_id = urlParams.get("p");

  const [company_infos, setCompanyInfos] = useState(undefined);

  const onShowFinishButton = (val) => {
    showFinishButton(val);
  };

  const onCompanyInfoChanged = (val) => {
    setCompanyInfos(val);
  };

  const getCompanyInfos = async function () {
    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    try {
      const response = await databases.getDocument("default", "client_infos", localStorage.getItem("companyId"));
      console.log("getCompanyInfos", response);
      onCompanyInfoChanged(response);
      return response;
    } catch (error) {
      console.log(error); // Failure
      return null;
    }
  };

  const isNumber = (str) => {
    const num = Number(str);
    return !isNaN(num) && typeof num === "number";
  };

  return (
    <>
      {email_count && plan_id && (
        <React.Fragment>
          <div className="bg-pattern" style={{ height: "100vh" }}>
            <div className="account-pages pt-5">
              <Container>
                <Row className="w-100 justify-content-center">
                  <Col lg={12} md={6} xl={6}>
                    <Card className="mt-5">
                      <CardBody className="p-4">
                        <div className="">
                          {/* <div className="text-center">
                            <Link to="/" className="">
                              <div
                                className=""
                                style={{
                                  marginTop: "3vh",
                                  color: "#151d48",
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "54px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "140%",
                                }}>
                                {paddleEvent ? "" : "Vérification ..."}
                              </div>
                            </Link>
                          </div>
                          <form className="form-horizontal" action="#">
                            <Row>
                              <Col className="d-flex flex-column align-items-center" md={12}>
                                {loading && <span className="spinner-border spinner-border-xxl me-2 mt-4" role="status" aria-hidden="true"></span>}
                                {loading && (
                                  <p className="mt-3 font-primary text-center font-size-16">
                                    Nous avons presque terminé, veuillez patienter s'il vous plait, nous vous redirigeons...
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </form> */}
                          <div class="checkout-container"></div>
                          {show_finish_button && (
                            <div className="mt-3 mb-1 w-100 d-flex justify-content-center">
                              <button
                                onClick={(e) => {
                                  navigate(`/dashboard`);
                                }}
                                style={{
                                  backgroundColor: "#563bff",
                                  borderRadius: "20px",
                                }}
                                className="view-button simple-text font-primary">
                                {props.t("Terminer")}
                              </button>
                            </div>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                    <div className="mt-5 text-center">
                      <p className="simple-text font-primary">© {new Date().getFullYear()} Maaia. By SemeCloud</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default withTranslation()(Checkout);
