import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import "../../assets/scss/custom/pages/_welcome-dashboard.scss";

import helloIconPath from "./../../assets/images/waving-hand.png";

import { Client, Databases, Query, Account, ID } from "appwrite";

import { withTranslation } from "react-i18next";
import "moment/locale/fr";
import "moment/locale/es";
const moment = require("moment-timezone");

const WelcomeSection = (props) => {
  const [userInfos, setUserInfos] = useState(undefined);

  function formatDateForUI(timezone) {
    // Sélectionnez la locale de moment en fonction de la langue
    switch (props.i18n.language) {
      case "fr":
        moment.locale("fr");
        break;
      case "es":
        moment.locale("es");
        break;
      // Ajoutez d'autres cas de langue au besoin
      default:
        moment.locale("en"); // Langue par défaut
    }

    const currentDate = moment.tz(timezone);
    const formattedDate = currentDate.format("DD MMMM YYYY");
    return formattedDate;
  }

  // Exemple d'utilisation de la fonction
  const userTimezone = "Europe/Paris";

  async function getUserAccount() {
    // setRequest(true);

    const client = new Client();
    const account = new Account(client);

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const promise = account.get();
    return promise.then(
      async function (response) {
        // console.log("userAccount is", response);
        // setRequest(false);
        //     if(company_infos.mail_config){
        //    }
        //fetchMailBoxes(data);
        const user = response;
        setUserInfos(user);
      },
      function (error) {
        console.log("Error getting user Account ", error);
        console.log(error); // Failure
        // setRequest(false);
      }
    );
  }
  useEffect(() => {
    getUserAccount();
  }, []);
  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          <div className="welcome-section__container mb-xxl-4 mb-0 d-flex justify-content-between">
            <div className="welcome-section__right d-flex">
              {/* <div className="dateAndhour__conatainer d-flex">
                <span className="date" style={{ marginRight: '5px' }}>
                  {formatDateForUI(userTimezone)}
                </span>
              </div> */}
              <div className="greetings__container">
                <div className="greetings-text font-primary">{props.t("Welcome")}</div>
                <div className="d-flex">
                  <div className="employee-name font-primary">{userInfos ? userInfos.prefs.first_name : ""} !</div>
                  <span
                    className="hello-icon font-primary"
                    style={{
                      background: "url(" + helloIconPath + ")",
                      backgroundSize: "70% 70%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}></span>
                </div>
              </div>
              <div className="greetings-text__sub font-primary">{props.t("Vous êtes actuellement sur le Dashboard de Maaia.")}</div>
            </div>
            <div className="welcome-section__left d-flex font-primary">
              <button
                className="ms-auto view-tutorial-button black-button d-flex align-items-center font-primary"
                onClick={() => props.setStepsEnabled(true)}>
                {/* <span>
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.4 14.104C6.98933 14.104 6.672 13.992 6.448 13.768C6.224 13.5253 6.112 13.1987 6.112 12.788V8.196H1.604C1.212 8.196 0.904 8.09333 0.68 7.888C0.456 7.664 0.344 7.356 0.344 6.964C0.344 6.572 0.456 6.27333 0.68 6.068C0.904 5.844 1.212 5.732 1.604 5.732H6.112V1.28C6.112 0.869333 6.224 0.551999 6.448 0.327999C6.672 0.103999 6.99867 -0.00800037 7.428 -0.00800037C7.83867 -0.00800037 8.14667 0.103999 8.352 0.327999C8.576 0.551999 8.688 0.869333 8.688 1.28V5.732H13.196C13.6067 5.732 13.9147 5.844 14.12 6.068C14.344 6.27333 14.456 6.572 14.456 6.964C14.456 7.356 14.344 7.664 14.12 7.888C13.9147 8.09333 13.6067 8.196 13.196 8.196H8.688V12.788C8.688 13.1987 8.576 13.5253 8.352 13.768C8.14667 13.992 7.82933 14.104 7.4 14.104Z"
                      fill="white"
                    />
                  </svg>
                </span> */}
                {props.t("Follow the tutorial")}
              </button>
              {/* <button
                className="view-tutorial-button primary-button d-flex align-items-center font-primary"
                onClick={() => props.setmodal_list(true)}>
                {props.t("Revoir la vidéo d’aide")}
              </button> */}
              {/* <div
                style={{
                  position: 'absolute',
                  bottom: '-28px',
                  left: '-32px',
                }}>
                {Icons.cat}
              </div>
              <div style={{ position: 'absolute', top: '22px', left: '-32px' }}>{Icons.chat_bubbles}</div>
              <div
                style={{
                  position: 'absolute',
                  right: '40.87px',
                  top: '-25px',
                  zIndex: '1',
                }}>
                {Icons.frame}
              </div>
              <div
                style={{
                  position: 'absolute',
                  left: '172px',
                  zIndex: '2',
                  top: '4px',
                }}>
                {Icons.clock}
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '-32px',
                  top: '-28px',
                  zIndex: '0',
                }}>
                {Icons.background}
              </div> */}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withTranslation()(WelcomeSection);
