import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import { Client, Account } from "appwrite";
import { getUser } from "../../helpers/api_helper";
import logo_maaia from "../../assets/images/logo/maaia_logo_1.png";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
  Toast,
  ToastHeader,
  ToastBody,
  InputGroup,
  InputGroupText,
} from "reactstrap";
// router
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// actions
import { apiError, loginUser, socialLogin } from "../../store/actions";
//Import config
import { facebook, google } from "../../config";

//
import eye_icon from "../../assets/images/view 1.png";
import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import { withTranslation } from "react-i18next";

const Login = (props) => {
  document.title = "Login | IA - Smart Assistant";
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const togglePasswordVisibility = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  const toggleToast = () => {
    console.log("setToast before: " + toast);
    setToast(!toast);
    console.log("setToast after: " + toast);

    setTimeout(() => {
      if (!toast === true) {
        setToast(false);
      }
    }, 5000);
  };
  //const navigate = useNavigate();

  const dispatch = useDispatch();

  const onRequestIsLoading = (value) => {
    setRequestIsLoading(value);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "" || "",
      password: "" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Veuillez entrer votre Email")),
      password: Yup.string().required(props.t("Veuillez entrer votre mot de passe")),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));

      // onRequestIsLoading(true);

      // const client = new Client();

      // const account = new Account(client);

      // client.setEndpoint('https://api.shopybase.com/v1').setProject('ai_project_dev');
      // const promise = account.createEmailPasswordSession(validation.values.email, validation.values.password);

      // promise.then(
      //   async function (response) {
      //     console.log('Success on session create at login page');
      //     console.log(response);
      //     onRequestIsLoading(false);
      //     localStorage.setItem('authUser', JSON.stringify(response));
      //     localStorage.setItem('user', JSON.stringify(response));

      //     const user = await getUser();
      //     if (user.prefs["finished_onbording"]) {
      //       props.router.navigate('/dashboard', {
      //         replace: false,
      //       });
      //     } else {
      //       props.router.navigate('/setup', {
      //         replace: false,
      //       });
      //     }

      //   },
      //   function (error) {
      //     console.log('error on session check', error);
      //     onRequestIsLoading(false);
      //     dispatch(apiError(error.message));
      //     //   form.error_text = error;
      //   }
      // );
    },
  });

  const { error, loading } = useSelector((state) => ({
    error: state.login.error,
    loading: state.login.loading,
  }));

  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   dispatch(loginUser(values, props.router.navigate));
  // };

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = (response) => {
    signIn(response, "facebook");
  };

  useEffect(() => {
    dispatch(apiError(""));
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  }, [dispatch]);

  const svgs = {
    eye_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=""
        style={{ width: "24px" }}>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
        />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </svg>
    ),
    eye_slash_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=""
        style={{ width: "24px" }}>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
        />
      </svg>
    ),
  };
  return (
    <React.Fragment>
      <div className="account-pages d-flex align-items-center" style={{ minHeight: "100vh", overflow: "hidden" }}>
        <Container fluid={true}>
          <Row className="justify-content-center mb-3">
            <Col lg={12} md={12} xl={10} xxl={10} className="d-flex">
              <div
                className="font-primary ms-auto px-2"
                style={{
                  backgroundColor: "white",
                  height: "fit-content",
                  borderRadius: "10px",
                  boxShadow: "0px 6px 24px 1px rgba(53, 22, 252, 0.1)",
                }}>
                <LanguageDropdown hasArrow />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center m-0">
            <Col lg={12} md={12} xl={10} xxl={10}>
              <div className="auth-card">
                <Row>
                  <div className="auth-card__left col-xl-6 d-flex justify-content-center">
                    {/* <div className="auth-card_image-div"></div> */}
                    <img src={logo_maaia} height={200} />
                  </div>
                  <div className="login-card__right col-xl-6 pt-xl-5 pb-xl-5">
                    <Form
                      className="form-horizontal col-10"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      <div className="text-center py-3">
                        <Label className="font-primary" style={{ textAlign: "center", fontSize: "18px" }}>
                          {props.t("CONNEXION")}
                        </Label>
                      </div>
                      {error ? (
                        <Alert color="danger">
                          <div>{error}</div>
                        </Alert>
                      ) : null}
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label">{props.t("Email")}</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder={props.t("Entrer email")}
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={validation.touched.email && validation.errors.email ? true : false}
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.email}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">{props.t("password")}</Label>
                            <InputGroup>
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={isPasswordHidden ? "password" : "text"}
                                placeholder={props.t("Entrer Mot de passe")}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={validation.touched.password && validation.errors.password ? true : false}
                              />
                              <InputGroupText
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  padding: "0",
                                }}>
                                <button
                                  className="btn h-100 form-control"
                                  type="button"
                                  onClick={togglePasswordVisibility}
                                  style={{
                                    borderTopLeftRadius: "0",
                                    borderBottomLeftRadius: "0",
                                  }}>
                                  {isPasswordHidden ? svgs.eye_icon : svgs.eye_slash_icon}
                                </button>
                              </InputGroupText>
                              {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  <div> {validation.errors.password} </div>
                                </FormFeedback>
                              ) : null}
                            </InputGroup>
                          </div>

                          <Row className="align-items-center justify-content-between">
                            <Col xl={8}>
                              <div className="form-check d-flex align-items-center gap-2">
                                <input type="checkbox" className="form-check-input" id="customControlInline" />
                                <label className="form-label form-check-label font-primary" htmlFor="customControlInline">
                                  {props.t("Se rappeler de moi")}
                                </label>
                              </div>
                            </Col>
                            <Col xl={4}>
                              <button
                                disabled={loading}
                                className="btn btn-primary waves-effect waves-light"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#FF7049",
                                  border: "none",
                                  color: "#FFF",
                                  borderRadius: "10px",
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "17.005px",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "29.152px",
                                  boxShadow: "0px 19.683547973632812px 45.92827606201172px rgba(255, 112, 73, 0.20)",
                                }}
                                type="submit">
                                {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />}{" "}
                                {props.t("Connexion")}
                                {/* <Spinner animation="border" /> */}
                              </button>
                            </Col>
                          </Row>

                          <Row className="align-items-center justify-content-between mt-4">
                            <Col className="">
                              {/* <div
                                className=""
                                style={{
                                  color: " #737791",
                                  fontSize: "15px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }}>
                                {props.t("Pas encore de compte ?")}{" "}
                                <Link to="/register" className="fw-medium text-primary">
                                  {" "}
                                  {props.t("Ouvrir mon compte")}{" "}
                                </Link>{" "}
                              </div> */}
                            </Col>
                            <Col className="col-auto">
                              <div className="text-md-end mt-3 mt-md-0">
                                <Link
                                  to="/auth-recoverpw"
                                  className="text-muted"
                                  style={{
                                    color: "#737791",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "15px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                  }}>
                                  {props.t("Mot de passe oublié ?")}
                                </Link>
                              </div>
                            </Col>
                          </Row>

                          {/* <Row className="mt-5">
                              <Col xl={5}>
                                <hr style={{ borderTopColor: '#AEB7D6' }} />
                              </Col>
                              <Col xl={2} className="d-flex align-items-center justify-content-center">
                                <span>ou</span>
                              </Col>
                              <Col xl={5}>
                                <hr style={{ borderTopColor: '#AEB7D6' }} />
                              </Col>
                            </Row> */}

                          {/* <Row className="mt-5 text-center">
                              <FacebookLogin
                                appId={facebook.APP_ID}
                                autoLoad={false}
                                callback={facebookResponse}
                                render={(renderProps) => (
                                  <Link
                                    to="#"
                                    className="border-none"
                                    style={{
                                      background: '#1877F2',
                                      borderRadius: '10px',
                                      width: '100%',
                                      height: '54px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '15px',
                                    }}
                                    onClick={renderProps.onClick}>
                                    <i className="mdi mdi-facebook" style={{ fontSize: '30px', color: '#fff' }} />
                                    <span
                                      style={{
                                        color: '#FFF',
                                        fontFamily: 'Helvetica',
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: 'normal',
                                      }}>
                                      Connexion avec Facebook
                                    </span>
                                  </Link>
                                )}
                              />
                            </Row> */}
                          {/* <Row className="mt-4">
                              <GoogleLogin
                                clientId={google.CLIENT_ID}
                                render={(renderProps) => (
                                  <Link
                                    to="#"
                                    className="border-none"
                                    style={{
                                      borderRadius: '10px',
                                      background: '#FFF',
                                      boxShadow: '0px 0px 117.2px 0px rgba(0, 0, 0, 0.21)',
                                      width: '100%',
                                      height: '54px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '15px',
                                    }}
                                    onClick={renderProps.onClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                      <rect width="24" height="24" transform="translate(0.5)" fill="white" />
                                      <path
                                        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                                        d="M23.54 12.2605C23.54 11.445 23.4668 10.6609 23.3309 9.9082H12.5V14.3566H18.6891C18.4225 15.7941 17.6123 17.0121 16.3943 17.8275V20.713H20.1109C22.2855 18.7109 23.54 15.7627 23.54 12.2605Z"
                                        fill="#4285F4"
                                      />
                                      <path
                                        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                                        d="M12.4995 23.5005C15.6045 23.5005 18.2077 22.4708 20.1104 20.7144L16.3938 17.829C15.364 18.519 14.0467 18.9267 12.4995 18.9267C9.50425 18.9267 6.96902 16.9037 6.0647 14.1855H2.22266V17.1651C4.11493 20.9235 8.00402 23.5005 12.4995 23.5005Z"
                                        fill="#34A853"
                                      />
                                      <path
                                        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                                        d="M6.06523 14.1855C5.83523 13.4955 5.70455 12.7584 5.70455 12.0005C5.70455 11.2425 5.83523 10.5055 6.06523 9.81548V6.83594H2.22318C1.44432 8.38844 1 10.1448 1 12.0005C1 13.8562 1.44432 15.6125 2.22318 17.165L6.06523 14.1855Z"
                                        fill="#FBBC05"
                                      />
                                      <path
                                        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                                        d="M12.4995 5.07386C14.1879 5.07386 15.7038 5.65409 16.8956 6.79364L20.194 3.49523C18.2024 1.63955 15.5992 0.5 12.4995 0.5C8.00402 0.5 4.11493 3.07705 2.22266 6.83545L6.0647 9.815C6.96902 7.09682 9.50425 5.07386 12.4995 5.07386Z"
                                        fill="#EA4335"
                                      />
                                    </svg>
                                    <span
                                      style={{
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        fontFamily: 'Roboto, sans-serif',
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                      }}>
                                      Sign In with Google
                                    </span>
                                  </Link>
                                )}
                                onSuccess={googleResponse}
                                onFailure={() => { }}
                              />
                            </Row> */}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Row>
              </div>
              {/* <div className="mt-4 text-center">
                <p
                  className=""
                  style={{
                    color: "#737791",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}>
                  Copyright © {new Date().getFullYear()}
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
        {/* <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: "11" }}>
          <Toast isOpen={toast}>
            <ToastHeader toggle={toggleToast}>
              Erreur lors de la connexion
            </ToastHeader>
            <ToastBody color="primary">{toastMessage}</ToastBody>
          </Toast>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(Login));

Login.propTypes = {
  history: PropTypes.object,
};
