/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Client, Databases, Query } from "appwrite";

import {
  Toast,
  ToastHeader,
  ToastBody,
  Spinner,
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  InputGroupText,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import CustomToast from "../../components/Common/customToast/CustomToast";
import { getCompanyInfos } from "../../helpers/api_helper";

const events = [
  { tag: "session_created", fr: "Session créée", en: "Session created", es: "Sesión creada" },
  { tag: "mail_box_created", fr: "Dossier mail créé", en: "Mailbox created", es: "Buzón de correo creado" },
  { tag: "mail_reply", fr: "Réponse à un email", en: "Email reply", es: "Respuesta de correo" },
  {
    tag: "ia_autogen_request",
    fr: "Demande de génération automatique par IA",
    en: "AI autogeneration request",
    es: "Solicitud de generación automática por IA",
  },
  { tag: "topic_added", fr: "Topic ajouté", en: "Topic added", es: "Tema agregado" },
  { tag: "topic_deleted", fr: "Topic supprimé", en: "Topic deleted", es: "Tema eliminado" },
  { tag: "topic_updated", fr: "Topic mis à jour", en: "Topic updated", es: "Tema actualizado" },
  { tag: "tag_updated", fr: "Tag mis à jour", en: "Tag updated", es: "Etiqueta actualizada" },
  { tag: "shopify_access_updated", fr: "Accès Shopify mis à jour", en: "Shopify access updated", es: "Acceso a Shopify actualizado" },
  { tag: "store_type_updated", fr: "Type de magasin mis à jour", en: "Store type updated", es: "Tipo de tienda actualizado" },
  {
    tag: "email_config_updated",
    fr: "Configuration email mise à jour",
    en: "Email configuration updated",
    es: "Configuración de correo actualizada",
  },
  { tag: "profil_details_updated", fr: "Détails du profil mis à jour", en: "Profile details updated", es: "Detalles del perfil actualizados" },
  { tag: "agent_added", fr: "Agent ajouté", en: "Agent added", es: "Agente agregado" },
  { tag: "agent_enabled", fr: "Agent activé", en: "Agent enabled", es: "Agente habilitado" },
  { tag: "agent_disabled", fr: "Agent désactivé", en: "Agent disabled", es: "Agente deshabilitado" },
  { tag: "agent_updated", fr: "Agent mis à jour", en: "Agent updated", es: "Agente actualizado" },
  { tag: "agent_deleted", fr: "Agent supprimé", en: "Agent deleted", es: "Agente eliminado" },
  { tag: "employee_added", fr: "Employé ajouté", en: "Employee added", es: "Empleado agregado" },
  { tag: "employee_updated", fr: "Employé mis à jour", en: "Employee updated", es: "Empleado actualizado" },
  { tag: "employee_deleted", fr: "Employé supprimé", en: "Employee deleted", es: "Empleado eliminado" },
  { tag: "notifications_updated", fr: "Notifications mises à jour", en: "Notifications updated", es: "Notificaciones actualizadas" },
  { tag: "password_updated", fr: "Mot de passe mis à jour", en: "Password updated", es: "Contraseña actualizada" },
  { tag: "lang_updated", fr: "Langue mise à jour", en: "Language updated", es: "Idioma actualizado" },
  { tag: "timezone_updated", fr: "Fuseau horaire mis à jour", en: "Timezone updated", es: "Zona horaria actualizada" },
  { tag: "mail_created", fr: "Email créé", en: "Email created", es: "Correo creado" },
  { tag: "addon_updated", fr: "Extension mise à jour", en: "Addon updated", es: "Complemento actualizado" },
  { tag: "payment_done", fr: "Paiement effectué", en: "Payment done", es: "Pago realizado" },
  { tag: "billing_plan_updated", fr: "Plan de facturation mis à jour", en: "Billing plan updated", es: "Plan de facturación actualizado" },
  {
    tag: "2fa_disabled",
    fr: "Authentification à deux facteurs désactivée",
    en: "Two-factor authentication disabled",
    es: "Autenticación de dos factores deshabilitada",
  },
  {
    tag: "2fa_enabled",
    fr: "Authentification à deux facteurs activée",
    en: "Two-factor authentication enabled",
    es: "Autenticación de dos factores habilitada",
  },
  {
    tag: "2fa_updated",
    fr: "Authentification à deux facteurs mise à jour",
    en: "Two-factor authentication updated",
    es: "Autenticación de dos factores actualizada",
  },
  {
    tag: "billing_details_updated",
    fr: "Détails de facturation mis à jour",
    en: "Billing details updated",
    es: "Detalles de facturación actualizados",
  },
];
const Log = (props) => {
  const { userInfo } = props;
  const navigate = useNavigate();
  const [log, setLog] = useState([]);
  const [loadingIcon, setloadingIcon] = useState({
    loadCDDDdata: true,
    showDeleteIcon: false,
    loadCDDdataRemove: false,
  });
  const [returnID, setReturnID] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [toast, settoast] = useState(false);
  const [deleteCount, setDeleteCount] = useState({
    countElement: "",
    totalElement: "",
  });
  const [searchData, setSearchData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchType, setSearchType] = useState("author");

  const [currentPage, setCurrentPage] = useState(1);
  const [prevCursor, setCursor] = useState(undefined);
  const [perPage, setPerPage] = useState(10);
  const [totalRowsCustom, setTotalRowsCustom] = useState(0);
  const [loading, setLoading] = useState(true);

  const [singlebtn, setSinglebtn] = useState(false);

  useEffect(() => {
    fetchLogs(userInfo.$id, 1);
  }, []);

  const convertHourlyToDate = (timestamp) => {
    if (timestamp) {
      return new Date(timestamp).toLocaleDateString(props.i18n.language + "-" + props.i18n.language.toUpperCase(), {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    }

    return "-";
  };

  const column = [
    {
      name: <span className="font-weight-bold fs-13">{props.t("Evènement")} </span>,
      selector: (row) => <td> {props.t(row.event)}</td>,
    },
    {
      name: <span className="font-weight-bold fs-13">{props.t("Auteur")}</span>,
      selector: (row) => <td> {row.author_id === userInfo.$id ? props.t("Vous") : row.author_id}</td>,
    },
    {
      name: <span className="font-weight-bold fs-13">{props.t("Date")}</span>,
      selector: (row) => <td> {convertHourlyToDate(row.$createdAt)}</td>,

      sortable: true,
    },
  ];

  async function getCompanyLogs(userId, limit, cursor, cursorBefore, THE_TEXT_TO_SEARCH) {
    const client = new Client();
    const databases = new Databases(client, "default");
    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const queries = [Query.equal("company_id", userId), Query.limit(parseInt(limit)), Query.orderDesc("$createdAt")];
    if (cursor) {
      if (cursorBefore) {
        queries.push(Query.cursorBefore(cursor));
      } else {
        queries.push(Query.cursorAfter(cursor));
      }
    }
    if (THE_TEXT_TO_SEARCH) {
      if (searchType === "author") {
        queries.push(Query.search("author_id", THE_TEXT_TO_SEARCH));
      } else if (searchType === "event") {
        queries.push(Query.equal("event", THE_TEXT_TO_SEARCH));
      } else if (searchType === "date") {
        queries.push(Query.startsWith("$createdAt", THE_TEXT_TO_SEARCH));
      }
    }
    const promise = databases.listDocuments("default", "logs", queries);

    return promise.then(
      async function (response) {
        return response;
      },
      function (error) {
        console.log("Error listing company logs ", error);
        return null;
      },
    );
  }

  const handleSelectElement = async ({ selectedRows }) => {
    setloadingIcon((prev) => ({ ...prev, showDeleteIcon: false }));

    if (selectedRows.length > 0) {
      setloadingIcon((prev) => ({ ...prev, showDeleteIcon: true }));
      const id = selectedRows.map((map) => map.$id);

      setReturnID(id);
      // console.log({ returnID });
    }
  };
  const removeMultipleCompany = async () => {
    setloadingIcon((prev) => ({ ...prev, loadCDDdataRemove: true }));
    setDeleteCount((prev) => ({ ...prev, totalElement: returnID.length }));
    toggleToast();
    for (let index = 0; index < returnID.length; index++) {
      // const element = returnID[index];
      //   const val = await deleteCompany(element);
      if (index === 1) {
        setDeleteCount((prev) => ({ ...prev, countElement: index + 1 }));
      }
    }
    // const contrats = await getClients();
    // setCompany(contrats);
    setloadingIcon((prev) => ({
      ...prev,
      loadCDDdataRemove: false,
      showDeleteIcon: false,
    }));
    handleClearRows();
  };
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };
  const toggleToast = () => {
    settoast(!toast);
  };

  const renderCompanyList = (logList) => {
    if (loadingIcon.loadCDDDdata) {
      return (
        <div style={{ display: "grid", placeItems: "center", height: "50vh" }}>
          <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z" />
            <rect x="11" y="6" rx="1" width="2" height="7">
              <animateTransform attributeName="transform" type="rotate" dur="9s" values="0 12 12;360 12 12" repeatCount="indefinite" />
            </rect>
            <rect x="11" y="11" rx="1" width="2" height="9">
              <animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite" />
            </rect>
          </svg>
        </div>
      );
    } else if (!logList) {
      return <div>{props.t("Échec de chargement")}</div>;
    } else if (logList) {
      return (
        <Col lg="12">
          <Card style={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <Row className="align-items-center justify-content-between flex-row mb-4">
              <div style={{ width: "fit-content" }}>
                <h4 className="section-title font-primary mb-0" style={{ fontSize: "14px" }}>
                  {props.t("Logs")}
                </h4>
              </div>
              <InputGroup
                className="ms-auto shadow"
                style={{
                  width: "fit-content",
                  backgroundColor: "white",
                  borderRadius: "13.511px",
                }}>
                <InputGroupText
                  onClick={handleInputSearch}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    paddingRight: "0",
                    paddingLeft: "0",
                  }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21 21L18.4953 18.4953C18.1033 18.1033 17.9073 17.9073 17.7092 17.8311C17.5121 17.7553 17.3684 17.7425 17.161 17.7825C16.9527 17.8226 16.6814 18.0117 16.1387 18.3897C14.6817 19.4048 12.9104 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 12.2414 19.7487 13.4241 19.2941 14.5"
                      stroke="#5D5FEF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </InputGroupText>
                <Input
                  placeholder={props.t("Recherche") + "..."}
                  style={{
                    font: "normal 400 15.2px/normal Poppins, sans-serif",
                    border: "none",
                    backgroundColor: "transparent",
                    color: "#737791",
                  }}
                  type={searchType === "date" ? "date" : "text"}
                  value={searchTerm}
                  onChange={handleSearchInput}
                  onKeyDown={handleKeyDown}
                  className="font-primary"
                />
                <InputGroupText
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    paddingRight: "0",
                    paddingLeft: "0",
                  }}>
                  <Dropdown isOpen={singlebtn} toggle={() => setSinglebtn(!singlebtn)} className="dropdown d-inline-block ms-1" tag="li">
                    <DropdownToggle
                      className="p-0 m-0"
                      style={{
                        background: "transparent",
                        border: "none",
                      }}
                      tag="button">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M13 21.0001H21M9.99999 14.9996H6.99999M21 14.9996H14M9 9.00006H21M13 2.99982H3M21 2.99982H17"
                          stroke="#5D5FEF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu dropdown-menu-md dropdown-menu-end">
                      <DropdownItem disabled>
                        <span>{props.t("Recherche par")} :</span>
                      </DropdownItem>
                      <DropdownItem onClick={() => setSearchType("event")} active={searchType === "event"}>
                        <span>{props.t("Evènement")}</span>
                      </DropdownItem>
                      <DropdownItem onClick={() => setSearchType("author")} active={searchType === "author"}>
                        <span>{props.t("Auteur")}</span>
                      </DropdownItem>
                      <DropdownItem onClick={() => setSearchType("date")} active={searchType === "date"}>
                        <span>{props.t("Date")}</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </InputGroupText>
              </InputGroup>
            </Row>
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h4 className="card-title mb-4 font-primary">{props.t("Liste des Logs")}</h4>
                  {loadingIcon.showDeleteIcon && !loadingIcon.loadCDDdataRemove && (
                    <i className="fas fa-trash-alt font-size-24" style={{ cursor: "pointer", color: "#fd3b5e" }} onClick={removeMultipleCompany}></i>
                  )}
                  {loadingIcon.loadCDDdataRemove && (
                    <Spinner color="primary" size="md">
                      Loading...
                    </Spinner>
                  )}
                </div>

                <div className="table-responsive" style={{ minHeight: "50vh" }}>
                  <DataTable
                    columns={column}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    // onRowClicked={(e) => handleEditClick(e.$id)}
                    data={logList}
                    selectableRows
                    onSelectedRowsChange={handleSelectElement}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRowsCustom}
                    progressPending={loading}
                    onChangePage={handlePageChangeCustom}
                    noDataComponent={props.t("Il n'y a aucun enregistrement à afficher.")}
                    clearSelectedRows={toggledClearRows}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                    paginationComponentOptions={{
                      noRowsPerPage: false,
                      rangeSeparatorText: props.t("sur"),
                      rowsPerPageText: props.t("Nbre par page"),
                    }}
                  />
                </div>
              </div>
            </div>
          </Card>
        </Col>
      );
    }
  };

  const handleSearchInput = async (event) => {
    let val = event.target.value;
    // console.log({ val });
    setSearchTerm(val);
    if (val.length === 0) {
      fetchLogs(userInfo.$id, 1);
    }
  };

  const handleInputSearch = async (page, limit) => {
    setLoading(true);
    try {
      let text_to_search;
      switch (searchType) {
        case "event":
          text_to_search = getEventsTags(searchTerm);
          break;
        case "author":
          text_to_search = filteredUsers(searchTerm);
          break;

        default:
          text_to_search = new Date(searchTerm).toISOString().substring(0, 10);
          console.log({ text_to_search });
          break;
      }

      // console.log({ text_to_search });
      if (!text_to_search) {
        setLog([]);
      } else {
        fetchLogs(userInfo.$id, page ?? 1, limit ?? perPage, text_to_search);
      }
    } catch (error) {
      // console.log({ error });
    }
    setLoading(false);
  };

  const handlePageChangeCustom = (page) => {
    if (searchTerm) {
      handleInputSearch(page);
    } else {
      fetchLogs(userInfo.$id, page);
    }
  };

  const handleRowsPerPageChanged = async (rows) => {
    setPerPage(rows);
    if (searchTerm) {
      handleInputSearch(currentPage, rows);
    } else {
      fetchLogs(userInfo.$id, currentPage, rows);
    }
  };

  const fetchLogs = async (userId, page, limit, THE_TEXT_TO_SEARCH) => {
    try {
      setLoading(true);
      let cursorBefore;
      let cursor;
      if (page > 1 && page !== currentPage) {
        cursorBefore = page < currentPage;
        cursor = log.length > 0 ? (cursorBefore ? log[0].$id : log[log.length - 1].$id) : undefined;
      }
      if (page === currentPage && page !== 1) {
        cursorBefore = false;
        cursor = prevCursor;
      }
      setCursor(cursor);
      const response = await getCompanyLogs(userId, limit ?? perPage, cursor, cursorBefore, THE_TEXT_TO_SEARCH);
      console.log("Fetching logs", response);
      setTotalRowsCustom(response.total);

      setCurrentPage(page);
      //  const logs = await getCompanyLogs(userId);
      const employeeData = await getCompanyEmployeesData(userId);
      const clientInfos = await getCompanyInfos();
      const employeesList = employeeData[0].employees ?? [];
      const ownerData = employeeData[0].owner;
      let owner;
      if (ownerData) {
        owner = JSON.parse(ownerData);
      }

      const employees = employeesList.map((element) => JSON.parse(element));
      const agentData = clientInfos.agents.map((agent) => JSON.parse(agent));
      setLog(response.documents);
      setloadingIcon((prev) => ({ ...prev, loadCDDDdata: false }));
      const newAgentData = agentData.map((agent) => ({
        id: agent.id,
        name: agent.agent_name,
      }));
      const newEmployesData = employees.map((employe) => ({
        id: employe.id,
        name: `${employe.first_name} ${employe.last_name}`,
      }));
      const ownerForm = {
        id: owner.id,
        name: `${owner.first_name} ${owner.last_name}`,
      };
      const mergeData = [...newAgentData, ...newEmployesData, ownerForm];
      setSearchData(mergeData);
      setLoading(false);
    } catch (error) {
      setloadingIcon((prev) => ({ ...prev, showDeleteIcon: true }));
      setLoading(false);
      // setTaostNotification((prev) => ({
      //   ...prev,
      //   fail: true,
      // }));
      // setTimeout(() => {
      //   setTaostNotification((prev) => ({
      //     ...prev,
      //     fail: false,
      //   }));
      // }, 3000);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (searchTerm.length > 0) {
        handleInputSearch();
      }
    }
  };

  const filteredUsers = (input) => {
    const searchResult = searchData.filter((user) => user.name.toLowerCase().includes(input.toLowerCase()));
    return searchResult.length > 0 ? searchResult.at(0).id : null;
  };

  const getEventsTags = (input) => {
    const lang = props.i18n.language;
    const searchResult = events.filter((e) => e[lang].toLowerCase().includes(input.toLowerCase()));
    console.log({ searchResult });
    return searchResult.length > 0
      ? searchResult.map((result) => {
          return result.tag;
        })
      : null;
  };
  async function searchCompanyLogs(THE_TEXT_TO_SEARCH, OwnerID) {
    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    // console.log({ THE_TEXT_TO_SEARCH });
    // console.log({ OwnerID });
    let queries = [Query.equal("company_id", OwnerID), Query.limit(perPage), Query.orderDesc("$createdAt")];
    if (THE_TEXT_TO_SEARCH) {
      if (searchType === "author") {
        queries.push(Query.search("author_id", THE_TEXT_TO_SEARCH));
      } else if (searchType === "event") {
        queries.push(Query.equal("event", THE_TEXT_TO_SEARCH));
      } else if (searchType === "date") {
        queries.push(Query.startsWith("$createdAt", THE_TEXT_TO_SEARCH));
      }
    }
    const promise = databases.listDocuments("default", "logs", queries);

    return promise.then(
      async function (response) {
        // console.log({ response });
        return response;
      },
      function (error) {
        // console.log("Error listing company logs ", error);
        console.log(error);
        return null;
      },
    );
  }

  async function getCompanyEmployeesData(userId) {
    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const promise = databases.listDocuments("default", "employee", [Query.equal("company_id", userId)]);

    return promise.then(
      async function (response) {
        return response.documents;
      },
      function (error) {
        console.log("Error listing company employees ", error);
        return null;
      },
    );
  }

  return (
    <React.Fragment>
      <Container fluid={true} style={{ maxWidth: "60%" }}>
        {renderCompanyList(log)}
        <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: "1005" }}>
          <CustomToast
            isOpen={toast}
            toggle={toggleToast}
            message={{
              title: "MAIA-ADMIN",
              body: `${deleteCount.countElement} ${props.t("supprimé")}${deleteCount.countElement > 1 ? "s" : ""} ${props.t("sur")} ${
                deleteCount.totalElement
              }`,
              key: "default",
            }}
          />
        </div>
      </Container>
    </React.Fragment>
  );
};

export default withTranslation()(Log);
