const tagDescriptions = {
  product: "product_description",
  order: "order_description",
  feedback: "feedback_description",
  returns: "returns_description",
  delivery: "delivery_description",
  refunds: "refunds_description",
  payments: "payments_description",
  cancellations: "cancellations_description",
  customer_account: "customer_account_description",
  company_information: "company_information_description",
  influencers: "influencers_description",
  customer_loyalty: "customer_loyalty_description",
};
export default tagDescriptions;
