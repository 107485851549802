import React from "react";

function SendOutlined({ width, height, className, style }) {
  return (
    <svg width={width} height={height} className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1721_5789)">
        <path
          d="M11 12H8.78814C8.13568 12 7.80944 12 7.51559 12.0928C7.25552 12.1748 7.01499 12.3092 6.80878 12.4877C6.57577 12.6894 6.40479 12.9672 6.06284 13.5229L4.8822 15.4414C3.12611 18.2951 2.24806 19.7219 2.42974 20.5187C2.58655 21.2065 3.09409 21.7608 3.76542 21.9774C4.54316 22.2284 6.04163 21.4792 9.03858 19.9807L19.2757 14.8622C21.1181 13.941 22.0393 13.4803 22.3349 12.857C22.5922 12.3146 22.5922 11.6854 22.3349 11.143C22.0393 10.5197 21.1181 10.059 19.2757 9.13784L9.03859 4.0193C6.04164 2.52082 4.54316 1.77158 3.76542 2.02258C3.09409 2.23924 2.58655 2.79354 2.42974 3.48131C2.24806 4.2781 3.12611 5.70493 4.8822 8.55858L5.15385 9L5.5 9.5625"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1721_5789">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SendOutlined;
