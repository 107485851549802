import { SHOW_AGENT_STATUS, APPLY_CONSUMED_CREDIT, FETCH_CONSUMED_CREDIT, EMPTY_GENERAL_STATE } from "./actionTypes";

export const isActivatedAgentStatus = (active) => ({
  type: SHOW_AGENT_STATUS,
  payload: active,
});

export const applyConsumedCredit = (count, company_data) => ({
  type: APPLY_CONSUMED_CREDIT,
  payload: { consumed_email_count: count, company_infos: company_data },
});

export const fetchConsumedCreditRedux = () => {
  return {
    type: FETCH_CONSUMED_CREDIT,
    payload: {},
  };
};

export const emptyGeneralStateStorage = () => {
  return {
    type: EMPTY_GENERAL_STATE,
    payload: {},
  };
};
