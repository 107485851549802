import React, { useState, useEffect } from "react";
import { Elements, useStripe, useElements, PaymentElement, AddressElement, LinkAuthenticationElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  createStripeCheckoutSession,
  createStripePaymentEntent,
  stripe_payment_return_url,
  prestashop_payment_return_url,
  createStripeCustomer,
  maaia_dashboard_url,
  getStripeSubscription,
  createStripeSubscripion as createStripeSubscription,
  applyStripeDiscountCode,
  updateStripeSubscripion,
  retrieveStripeUpComingInvoice,
  getStripeProduct,
} from "../../helpers/api_helper";
import logo_maaia from "../../assets/images/icons-infos-purple.png";
import {
  Col,
  CardBody,
  Row,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Toast,
  ToastHeader,
  ToastBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Modal,
  InputGroup,
  InputGroupText,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import "./PaymentForm.scss";
import InfoMono from "../../assets/images/icons/InfoMono";
import PlusOutlined from "../../assets/images/icons/PlusOutlined";
import ArrowLeftOutlined from "../../assets/images/icons/ArrowLeftOutlined";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Client, Account, Databases, ID, Query } from "appwrite";
import { withTranslation } from "react-i18next";
import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
/*
const PaymentForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [formData, setFormData] = useState({
    country: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!elements) return;

    const paymentElement = elements.getElement("address");

    const handleChange = (event) => {
      if (event.complete) {
        console.log("Payment Element is complete.");
      }
    };

    if (paymentElement) {
      paymentElement.on("onChange", handleChange);
    }

    return () => {
      if (paymentElement) {
        paymentElement.off("onChange", handleChange);
      }
    };
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://your-website.com/order/complete",
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      console.log("Payment confirmed successfully");
      console.log("Country selected:", formData.country);
    }
  };

  const handleChange = (event) => {
    console.log({ change_event: event });

    if (event.complete) {
    }
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      style={{
        border: "1px solid #F6F9FC",
        borderRadius: "3px",
        padding: "20px",
        margin: "20px 0",
        boxShadow: "0 30px 50px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3)",
      }}>
      <LinkAuthenticationElement id="link-authentication-element" />
      <PaymentElement id="payment-element" />
      <AddressElement options={{ mode: "billing" }} onChange={handleChange} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        style={{
          backgroundColor: "#635BFF",
          borderRadius: "3px",
          color: "white",
          border: "0",
          padding: "12px 16px",
          marginTop: "16px",
          fontWeight: "600",
          cursor: "pointer",
          transition: "all 0.2s ease",
          display: "block",
        }}>
        <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}</span>
      </button>
      {message && (
        <div id="payment-message" style={{ margin: "10px 0" }}>
          {message}
        </div>
      )}
    </form>
  );
};
*/

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  // const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState(null);
  // const [country, setCountry] = useState(null);
  const [country, setCountry] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: props.mail_pack_id ? stripe_payment_return_url : prestashop_payment_return_url,
      },
    });
    setIsLoading(false);

    if (error) {
      setErrorMessage(error.message);
    } else {
      console.log("Payment confirmed successfully");
    }
  };
  console.log({ the_passed_region: props.region });

  // const defaultAdd = props.region === "US" ? { name: props.customer_fullName, address: { country: "US" } } : { name: props.customer_fullName };
  const defaultAdd = props.region === "US" ? { name: props.customer_fullName, address: { country: "US" } } : undefined;

  const handleAddressChange = (event) => {
    let the_country;
    console.log("Address changed:", event);
    if (event.elementType === "address" && event.elementMode === "billing") {
      the_country = event.value.address.country;
      const fullName = event.value.name;
      console.log("Address changed: country is", the_country);
      console.log("Name is", event.value.name);
      props.setCustomerFullName(fullName);
    }

    //   if (the_country && the_country === "US") {
    // if (the_country !== props.region) {
    if ((the_country && the_country === "US" && props.region !== "US") || (props.region === "US" && the_country && the_country !== "US")) {
      // if (the_country !== props.region ) {
      console.log("Need refresh");
      props.refreshStripePaymentIntent(the_country);
      //  }
    } else {
      console.log("No need refresh", the_country);
    }
    setCountry(the_country);

    // Gérer les changements d'adresse ici
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form d-flex" style={{ height: "100%" }}>
      <div className="my-auto w-100 py-4">
        <div className="form-group">
          <AddressElement
            options={{
              mode: "billing",
              // fields: {
              //   phone: "never",
              // },
              // validation: {
              //   phone: {
              //     required: "never",
              //   },
              // },
              defaultValues: defaultAdd,
            }}
            onChange={handleAddressChange}
          />
        </div>

        <div className="form-group">
          <PaymentElement
            options={{
              layout: {
                type: "accordion",
                defaultCollapsed: false,
                radios: false,
                spacedAccordionItems: false,
              },
            }}
          />
        </div>

        <button
          type="submit"
          disabled={isLoading || !stripe || !elements || props.isRefreshing}
          className="r_button"
          id="submit"
          // style={{
          //   backgroundColor: "#635BFF",
          //   borderRadius: "3px",
          //   color: "white",
          //   border: "0",
          //   padding: "12px 16px",
          //   marginTop: "16px",
          //   fontWeight: "600",
          //   cursor: "pointer",
          //   transition: "all 0.2s ease",
          //   display: "block",
          // }}
        >
          <span id="button-text">
            {isLoading || props.isRefreshing ? (
              <span className="spinner-border spinner-border-sm me-2 font-primary" role="status" aria-hidden="true" />
            ) : (
              props.t("Pay now")
            )}
          </span>
        </button>
      </div>

      {message && (
        <div id="payment-message" style={{ margin: "10px 0" }}>
          {message}
        </div>
      )}
    </form>
  );
};

const StripeCheckout = (props) => {
  const [clientSecret, setClientSecret] = useState(undefined);
  // const [stripePromise, setLoadStripe] = useState(undefined);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [planDetails, setPlanDetails] = useState(null);
  const [subscriptionIntent, setSubscriptionIntent] = useState(null);
  const [mailsPackDetails, setMailsPackDetails] = useState(null);
  const [stripeCustomerID, setStripeCustomerID] = useState(undefined);
  const [customer_email, setCustomerEmail] = useState(null);
  const [customer_fullName, setCustomerFullName] = useState(null);
  const [customerIsLoading, setCustomerIsLoading] = useState(null);
  const [company_infos, setCompanyInfos] = useState(undefined);

  const [couponCode, setCouponCode] = useState("");
  const [coupon, setCoupon] = useState(undefined);
  const [couponRequestMessage, setCouponRequestMessage] = useState("");
  const [couponRequestLoading, setCouponRequestLoading] = useState(false);

  const [showUpdateUI, setShowUpdateUI] = useState(false);

  const handlePromoCodeSubmit = async (e) => {
    e.preventDefault();
    setCouponRequestLoading(true);

    try {
      const response = await applyStripeDiscountCode(region, couponCode);

      if (response.valid) {
        setCoupon(response);
        setCouponRequestMessage(
          `${props.t("Code promo appliqué ! Réduction")} : ${response.percent_off ? response.percent_off : response.amount_off}${
            response.percent_off ? "%" : "€"
          }`,
        );
        fetchClientSecret(stripeCustomerID, company_infos, response);
      } else {
        setCouponRequestMessage(props.t("Code promo invalide"));
      }
    } catch (error) {
      setCouponRequestMessage(props.t("Erreur lors de la validation du code promo"));
    }

    setCouponRequestLoading(false);
  };

  //   const onLoadStripe = (val) => {
  //     setLoadStripe(val);
  //   };
  const navigate = useNavigate();
  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  let region = urlParams.get("region") || undefined;
  const emails_pack = urlParams.get("e") ?? 100;
  const plan_id = urlParams.get("p");

  const usa_infinity = "price_1Pno66Jcj0cebeM8snut8tYC";
  const usa_mail_pack = "price_1Po5d2Jcj0cebeM8fdeCqALq";
  const usa_essential = "price_1Pno6RJcj0cebeM8Kb05R8uj";
  const usa_cms_addon = "price_1PprzFJcj0cebeM8hHCIcGoZ";

  const world_infinity = "price_1Pno51B6CkBqvsv3xGNqsD85";
  const world_mail_pack = "price_1Po5t4B6CkBqvsv3j1nOcbDE";
  const world_essential = "price_1Pno5RB6CkBqvsv30aS5IQNH";
  const world_cms_addon = "price_1PprwiB6CkBqvsv36llqs4kK";

  const getPriceId = (plan_id, region) => {
    let id;
    switch (plan_id) {
      case "essential":
        id = region === "US" ? usa_essential : world_essential;
        break;
      case "infinity":
        id = region === "US" ? usa_infinity : world_infinity;
        break;
      default:
        id = region === "US" ? usa_cms_addon : world_cms_addon;
        break;
    }
    console.log({ id_is: id });

    return id;
  };

  let stripePromise;
  let mail_pack_id;
  let plan_item_id;
  // if (state && state.region === "US") {
  if (region && region === "US") {
    console.log("Load strip for US");

    stripePromise = loadStripe("pk_test_51Phy3pJcj0cebeM84jDwTilGOS4w2Pi1iWkeuWYb9KHbHiSw3YMcgYXIUyO1XiXVBG8nEW0xVI05b26xflXwCVNI00qOGt5w79");
    plan_item_id = getPriceId(plan_id, region);
    mail_pack_id = plan_id === "cms" ? undefined : usa_mail_pack;
  } else {
    console.log("Load strip for World");
    stripePromise = loadStripe("pk_test_51PncPYB6CkBqvsv3THhmNoSBr0TOcezJ83LszeqH4sCdOBkScgfThpFoHam6TvK1dbevCwy2xrGI4eUSasKDtSyj00kTHMRgDR");
    plan_item_id = getPriceId(plan_id, region);
    mail_pack_id = plan_id === "cms" ? undefined : world_mail_pack;
  }

  // useEffect(() => {
  //   const fetchPlanDetails = async () => {
  //     try {
  //       const response = await axios.get(`/get-product-details`, {
  //         params: { priceId: plan_item_id },
  //       });
  //       setPlanDetails(response.data.price);
  //       // setError(null); // Réinitialiser l'erreur en cas de succès
  //     } catch (err) {
  //       console.error("Erreur lors de la récupération des détails du produit:", err);
  //       //  setError("Impossible de charger les détails du produit. Veuillez réessayer.");
  //       //      setProductDetails(null);
  //     }
  //   };

  //   const fetchMailPackDetails = async () => {
  //     try {
  //       const response = await axios.get(`/get-product-details`, {
  //         params: { priceId: mail_pack_id },
  //       });
  //       setPlanDetails(response.data.price);
  //       // setError(null); // Réinitialiser l'erreur en cas de succès
  //     } catch (err) {
  //       console.error("Erreur lors de la récupération des détails du produit:", err);
  //       //  setError("Impossible de charger les détails du produit. Veuillez réessayer.");
  //       //      setProductDetails(null);
  //     }
  //   };

  //   fetchPlanDetails();
  //   fetchMailPackDetails();
  // }, [plan_item_id]);

  const onPlanDetails = async (val) => {
    setPlanDetails(val);
  };

  const handleEmailChange = (email) => {
    console.log("Email changed: email is", email);
    setCustomerEmail(email);
  };

  const refreshStripePaymentIntent = async (region) => {
    console.log("start refreshStripePaymentIntent");

    if (region === "US") {
      window.location.href = `${maaia_dashboard_url}/checkout?region=US&e=${emails_pack}&p=${plan_id}`;
    } else {
      window.location.href = `${maaia_dashboard_url}/checkout?e=${emails_pack}&p=${plan_id}`;
    }

    // navigate(`/checkout`, {
    //   replace: true,
    //   state: { region: "US" },
    // });

    // setIsRefreshing(true);

    // const stP = await loadStripe("pk_test_51Phy3pJcj0cebeM84jDwTilGOS4w2Pi1iWkeuWYb9KHbHiSw3YMcgYXIUyO1XiXVBG8nEW0xVI05b26xflXwCVNI00qOGt5w79");
    // console.log({ stripePromise: stP });

    // onLoadStripe(stP);

    // const response = await createStripePaymentEntent("US");
    // if (response) {
    //   setClientSecret(response.clientSecret);
    //   setIsRefreshing(false);
    // }
  };

  const handleCreateCustomer = async (email, name) => {
    setCustomerIsLoading(true);
    console.log("Started creating customer");

    let customer_data;
    if (region && region === "US") {
      customer_data = await createStripeCustomer("US", email, name);
      console.log({ customer_data });
    } else {
      customer_data = await createStripeCustomer(undefined, email, name);
      console.log({ customer_data });
    }
    setCustomerIsLoading(false);

    if (customer_data) {
      setStripeCustomerID(customer_data.customer.id);
      fetchClientSecret(customer_data.customer.id, company_infos);
    }
  };

  const fetchClientSecret = async (the_stripe_customer_id, the_company_infos, coupon) => {
    //   const stP = await loadStripe("pk_test_51PncPYB6CkBqvsv3THhmNoSBr0TOcezJ83LszeqH4sCdOBkScgfThpFoHam6TvK1dbevCwy2xrGI4eUSasKDtSyj00kTHMRgDR");
    //   console.log({ stripePromise: stP });
    //   onLoadStripe(stP);

    console.log({ coupon });

    setPageLoading(true);

    // if (state && state.region === "US") {

    const response = await createStripeSubscription(
      region,
      mail_pack_id ? stripe_payment_return_url : prestashop_payment_return_url,
      mail_pack_id
        ? [
            {
              price: plan_item_id,
              quantity: 1,
            },
            {
              price: mail_pack_id,
              quantity: (emails_pack ?? 100) / 100,
            },
          ]
        : [
            {
              price: plan_item_id,
              quantity: 1,
            },
          ],
      the_stripe_customer_id,
      {
        company_id: the_company_infos ? the_company_infos.$id : undefined,
        email: customer_email,
        name: customer_fullName,
        stripe_region: region,
        is_maaia_main_plan: mail_pack_id ? true : undefined,
      },
      coupon ? coupon.id : undefined,
    );

    setPageLoading(false);

    if (response) {
      const subscription = response.subscription;
      console.log({ the_subscription: subscription.latest_invoice.lines.data });
      const plan = subscription.latest_invoice.lines.data.find((line) => line.price.id === plan_item_id);
      const mailPack = subscription.latest_invoice.lines.data.find((line) => line.price.id === mail_pack_id);
      console.log({ plan });
      console.log({ mailPack });
      setSubscriptionIntent(subscription);

      setPlanDetails(plan);
      setMailsPackDetails(mailPack);

      setClientSecret(response.clientSecret);
    }
  };

  useEffect(() => {
    initCustomer();
  }, []);

  const initCustomer = async () => {
    setCustomerIsLoading(true);
    const user_string = localStorage.getItem("user");
    const user = user_string ? JSON.parse(user_string) : undefined;

    if (user_string && user && user.prefs && user.prefs["owner"] && (user.prefs["owner"] === "true" || user.prefs["owner"] === true)) {
      const the_company_infos = await getCompanyInfos();

      if (!the_company_infos) {
        console.log("No company infos");

        return;
      }
      setCompanyInfos(the_company_infos);

      console.log({ maaia_user_name: user });
      handleEmailChange(user.email);
      setCustomerFullName(user.prefs["first_name"] + " " + user.prefs["last_name"]);
      const stripe_customer_id = the_company_infos.stripe_customer_id;
      if (stripe_customer_id) {
        setStripeCustomerID(stripe_customer_id);

        const subscription_plan = await getStripeSubscription(the_company_infos.plan_id, the_company_infos.stripe_region, true, true);
        console.log({ subscription_plan });

        if (subscription_plan && plan_item_id !== subscription_plan.price_id) {
          setShowUpdateUI(subscription_plan);
        } else {
          await fetchClientSecret(stripe_customer_id, the_company_infos);
          region = the_company_infos.stripe_region;
        }
      }
    }
    setCustomerIsLoading(false);
  };

  const onCompanyInfoChanged = (val) => {
    setCompanyInfos(val);
  };

  const getCompanyInfos = async function () {
    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    try {
      const response = await databases.getDocument("default", "client_infos", localStorage.getItem("companyId"));
      console.log("getCompanyInfos", response);
      onCompanyInfoChanged(response);
      return response;
    } catch (error) {
      console.log(error); // Failure
      return null;
    }
  };

  const options = {
    paymentMethodOrder: ["apple_pay", "google_pay", "card", "klarna"],
    appearance: {
      theme: "stripe",
    },
    clientSecret,
    layout: {
      type: "accordion",
      defaultCollapsed: false,
    },
    locale: props.i18n.language,
  };

  return (
    <div
      className="d-flex align-items-center"
      style={{
        height: "100vh",
        backgroundColor: "white",
      }}>
      {showUpdateUI ? (
        <UpgradeDowngrageUI
          plan_id={plan_id}
          region={region}
          the_company_infos={company_infos}
          subscription_plan={showUpdateUI}
          preview_items={[
            {
              id: showUpdateUI.sub_item_id,
              deleted: true,
            },
            {
              id: showUpdateUI.mail_pack_sub_item_id,
              deleted: true,
            },
            {
              price: plan_item_id,
              quantity: 1,
            },
          ]}
          items={[
            {
              id: showUpdateUI.sub_item_id,
              price: plan_item_id,
              quantity: 1,
            },
          ]}
        />
      ) : (
        <>
          <Col
            className=""
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              paddingRight: "70px",
              overflow: "auto",
            }}>
            <div className="d-flex ms-auto" style={{ flexDirection: "column", width: "500px", height: "100%" }}>
              <div className="my-auto">
                <div className="d-flex align-items-center gap-1 mb-3">
                  <img src={logo_maaia} height={30} />
                  {/* <div
                className="logo_maaia"
                style={{
                  backgroundImage: `url(${logo_maaia})`,
                  backgroundPosition: "center center",
                }}></div> */}
                  <p className="font-size-16 mb-0 ms-1" style={{ fontWeight: "500" }}>
                    Maaia
                  </p>
                </div>

                <div className="d-flex mb-3" style={{ flexDirection: "column" }}>
                  <p className="font-size-16 mb-0" style={{ fontWeight: "500" }}>
                    Maaia
                  </p>
                  {subscriptionIntent && (
                    <span style={{ fontSize: "18px", color: "black", fontWeight: "bold" }}>
                      {subscriptionIntent.currency === "usd" ? "$" : "€"}
                      {subscriptionIntent.latest_invoice.total / 100}
                    </span>
                  )}

                  {planDetails && ProductCard(planDetails, true)}
                  {mailsPackDetails && ProductCard(mailsPackDetails)}

                  <div>
                    <div className="mt-3">
                      {subscriptionIntent && (
                        <div
                          className="d-flex align-items-center justify-content-between pb-3"
                          style={{
                            borderBottom: "1px solid rgba(15, 15, 15, 0.1)",
                          }}>
                          <div className="product_name">{props.t("Subtotal")}</div>
                          <span className="line_item_price">
                            {" "}
                            {subscriptionIntent.currency === "usd" ? "$" : "€"}
                            {subscriptionIntent.latest_invoice.subtotal / 100}
                          </span>
                        </div>
                      )}
                    </div>
                    {/* <div className="mt-3">
                  <button className="l_button py-2 px-3">Add promotion code</button>
                </div>
                <div
                  className="mt-3 d-flex gap-3 pb-3"
                  style={{
                    flexDirection: "column",
                    borderBottom: "1px solid rgba(15, 15, 15, 0.1)",
                  }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="">
                      Tax <InfoMono width={"14px"} height={"14px"} />
                    </span>
                    <span className="">Enter address to calculate</span>
                  </div>
                </div> */}

                    {subscriptionIntent && (
                      <div>
                        <div>
                          <input
                            className="w-100"
                            type="text"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            placeholder="Code promo"
                          />
                          <button className="l_button py-2 px-3 mt-2" onClick={handlePromoCodeSubmit} disabled={couponRequestLoading || coupon}>
                            {couponRequestLoading && (
                              <span className="spinner-border spinner-border-sm me-2 font-primary" role="status" aria-hidden="true" />
                            )}
                            {props.t(coupon ? "Appliqué" : "Appliquer le code")}
                          </button>
                        </div>

                        {couponRequestMessage && <div className="font-size-12">{couponRequestMessage}</div>}
                      </div>
                    )}

                    {subscriptionIntent && (
                      <div className="mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="product_name">{props.t("Total to pay today")}</div>
                          <span className="line_item_price">
                            {subscriptionIntent.currency === "usd" ? "$" : "€"}
                            {subscriptionIntent.latest_invoice.total / 100}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div className="mt-5">
              <div className="addon_card p-3">
                <img
                  className="product_image me-3"
                  src={"https://packaginghub.com/wp-content/uploads/2023/06/Types-of-Product-Packaging.webp"}
                  width={40}
                  height={40}
                />
                <div
                  className="d-flex w-100"
                  style={{
                    flexDirection: "column",
                  }}>
                  <div className="d-flex justify-content-between w-100">
                    <div className="product_name">Mails Pack</div>
                    <span className="line_item_price">{emails_pack}</span>
                  </div>
                  <p className="product_description w-100 my-1">
                    Reprehenderit butcher retro synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum
                    iphone. Seitan aliquip quis cardigan
                  </p>

                  <div className="mt-1">
                    <button className="l_button py-1 px-2 font-size-12">
                      <PlusOutlined height={14} className="me-1" /> Add to your order
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
                </div>
              </div>
            </div>
            <div style={{ alignSelf: "end" }}>
              <LanguageDropdown hasArrow />
            </div>
          </Col>
          <Col
            className=""
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              boxShadow: "-12px 0px 15px -4px rgba(128, 128, 128, 0.15)",
              paddingLeft: "70px",
              overflow: "auto",
            }}>
            <div style={{ width: "100%", height: "100%", alignItems: "center", display: "flex", justifyContent: "center" }}>
              {!stripeCustomerID && (
                <div className="form-group">
                  {/* <LinkAuthenticationElement onChange={handleEmailChange} /> */}

                  <label htmlFor="email">{props.t("E-mail")}</label>
                  <input
                    className="w-100"
                    id="email"
                    type="email"
                    value={customer_email}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    placeholder={props.t("Enter your e-mail address")}
                  />
                  <button
                    type="submit"
                    disabled={!customer_email}
                    className="r_button mt-3"
                    onClick={(e) => handleCreateCustomer(customer_email, customer_fullName)}>
                    <span id="button-text">
                      {customerIsLoading || props.isRefreshing ? (
                        <span className="spinner-border spinner-border-sm me-2 font-primary" role="status" aria-hidden="true" />
                      ) : (
                        props.t("Continuer")
                      )}
                    </span>
                  </button>
                </div>
              )}

              {pageLoading ? (
                <div style={{ width: "100%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                  <span className="spinner-border spinner-border-sm me-2 font-primary text-center" role="status" aria-hidden="true" />
                </div>
              ) : (
                clientSecret && (
                  <Elements stripe={stripePromise} options={options}>
                    {clientSecret && (
                      <PaymentForm
                        clientSecret={clientSecret}
                        refreshStripePaymentIntent={refreshStripePaymentIntent}
                        handleCreateCustomer={handleCreateCustomer}
                        isRefreshing={isRefreshing}
                        region={region}
                        planDetails={planDetails}
                        mailsPackDetails={mailsPackDetails}
                        setCustomerFullName={setCustomerFullName}
                        customer_fullName={customer_fullName}
                        mail_pack_id={mail_pack_id}
                        t={props.t}
                      />
                    )}
                  </Elements>
                )
              )}
            </div>
          </Col>
        </>
      )}
    </div>
  );

  function ProductCard(plan, is_plan) {
    return (
      <div className={is_plan ? "addon_card p-3" : ""}>
        <div className="mt-2">
          <div className="d-flex">
            <img
              className="product_image me-3"
              src={"https://corporate.theelegance.pk/wp-content/uploads/2023/09/Product-Packaging-Boxes.webp"}
              width={40}
              height={40}
            />
            <div
              className="d-flex w-100"
              style={{
                flexDirection: "column",
              }}>
              <div className="d-flex justify-content-between w-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <div className="product_name">{plan.description}</div>
                  <div>
                    <select className="qty_select">
                      {plan.plan.interval === "month" ? (
                        <option value="Monthly">{props.t("Monthly")}</option>
                      ) : (
                        <option value="Yearly">{props.t("Yearly")}</option>
                      )}
                    </select>
                  </div>
                </div>
                <span className="line_item_price">
                  {plan.plan.currency === "usd" ? "$" : "€"}
                  {plan.amount / 100}
                </span>
              </div>
              <p className="product_description w-100 mt-1">
                Reprehenderit butcher retro synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum
                iphone. Seitan aliquip quis cardigan
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export const UpgradeDowngrageUI = withTranslation()((props) => {
  const { region, the_company_infos, subscription_plan, items, preview_items } = props;
  const [invoicePreview, setInvoicePreview] = useState(undefined);
  const [planDetails, setPlanDetails] = useState(undefined);
  const [newSubscriptionPlanProduct, setNewSubscriptionPlanProduct] = useState(undefined);

  const [loading, setLoading] = useState(false);

  const getStripeUpComingInvoice = async (region, stripe_customer_id, subscription_plan_id, items) => {
    const response = await retrieveStripeUpComingInvoice(region, stripe_customer_id, subscription_plan_id, items);

    console.log({ retrieveStripeUpComingInvoiceResponse: response });

    if (response) {
      const invoice = response.invoice;
      console.log({ the_subscription: invoice.lines.data });
      const plan_details = invoice.lines.data;

      console.log({ plan_details });

      setInvoicePreview(invoice);
      setPlanDetails(plan_details);
    }
  };

  // const getNewSubscriptionPlanProduct = async () => {
  //   const subscription_plan_product = await getStripeProduct(plan_item_id, the_company_infos.stripe_region);
  //   console.log({ subscription_plan_product });
  //   setNewSubscriptionPlanProduct(subscription_plan_product);
  // };

  const handleUpdateSubscriptipon = async () => {
    setLoading(true);
    const response = await updateStripeSubscripion(region, subscription_plan.id, items);
    setLoading(false);
    console.log({ updateStripeSubscripion: response });
    if (response) {
      window.location.href = `${maaia_dashboard_url}/dashboard`;
    }
  };

  useEffect(() => {
    getStripeUpComingInvoice(region, the_company_infos.stripe_customer_id, subscription_plan.id, preview_items);
  }, []);

  return (
    <>
      <Col
        className=""
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingRight: "70px",
          overflow: "auto",
        }}>
        <div className="d-flex px-3 pt-5" style={{ flexDirection: "column", width: "600px", height: "100%", overflow: "auto" }}>
          <div className="my-auto">
            <div className="d-flex align-items-center gap-1 mb-3">
              <img src={logo_maaia} height={30} />
              <p className="font-size-16 mb-0 ms-1" style={{ fontWeight: "500" }}>
                Maaia
              </p>
            </div>

            <div className="mb-3 font-size-16">Pass current plan to {props.plan_id === "infinity" ? "Essential" : "Infinity"}</div>

            <div className="d-flex mb-3" style={{ flexDirection: "column" }}>
              <p className="font-size-16 mb-0" style={{ fontWeight: "500" }}>
                Maaia
              </p>
              {invoicePreview && (
                <span style={{ fontSize: "18px", color: "black", fontWeight: "bold" }}>
                  {invoicePreview.currency === "usd" ? "$" : "€"}
                  {invoicePreview.total / 100}
                </span>
              )}

              {planDetails &&
                planDetails.map((detail, idx) => (
                  <div className="pb-3" id={idx}>
                    {ProductCard(detail, true)}
                  </div>
                ))}
              <div>
                <div className="mt-3">
                  {invoicePreview && (
                    <div
                      className="d-flex align-items-center justify-content-between pb-3"
                      style={{
                        borderBottom: "1px solid rgba(15, 15, 15, 0.1)",
                      }}>
                      <div className="product_name">{props.t("Subtotal")}</div>
                      <span className="line_item_price">
                        {" "}
                        {invoicePreview.currency === "usd" ? "$" : "€"}
                        {invoicePreview.subtotal / 100}
                      </span>
                    </div>
                  )}
                </div>

                {invoicePreview && (
                  <div className="mt-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="product_name">{props.t("Total to pay today")}</div>
                      <span className="line_item_price">
                        {invoicePreview.currency === "usd" ? "$" : "€"}
                        {invoicePreview.total / 100}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <button
              className="r_button py-2 px-2 font-size-14 mt-2"
              onClick={() => {
                handleUpdateSubscriptipon();
              }}>
              {loading ? <span className="spinner-border spinner-border-sm me-2 font-primary" role="status" aria-hidden="true" /> : "Confirmer"}
            </button>
          </div>
        </div>
        <div style={{ alignSelf: "end" }}>
          <LanguageDropdown hasArrow />
        </div>
      </Col>
    </>
  );
  function ProductCard(plan, is_plan) {
    return (
      <div className={is_plan ? "addon_card p-3" : ""}>
        <div className="mt-2">
          <div className="d-flex">
            <img
              className="product_image me-3"
              src={"https://corporate.theelegance.pk/wp-content/uploads/2023/09/Product-Packaging-Boxes.webp"}
              width={40}
              height={40}
            />
            <div
              className="d-flex w-100"
              style={{
                flexDirection: "column",
              }}>
              <div className="d-flex justify-content-between w-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <div className="product_name">{plan.description}</div>
                  <div>
                    <select className="qty_select">
                      {plan.plan.interval === "month" ? (
                        <option value="Monthly">{props.t("Monthly")}</option>
                      ) : (
                        <option value="Yearly">{props.t("Yearly")}</option>
                      )}
                    </select>
                  </div>
                </div>
                <span className="line_item_price">
                  {plan.plan.currency === "usd" ? "$" : "€"}
                  {plan.amount / 100}
                </span>
              </div>
              <p className="product_description w-100 mt-1">
                Reprehenderit butcher retro synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum
                iphone. Seitan aliquip quis cardigan
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default withTranslation()(StripeCheckout);
