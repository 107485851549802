import { SUBSCRIPTION_CREDITS, USER_PLAN_FREE_TRANSLATION_LIMIT, USER_PLAN_STATUS, USER_PLAN_SUCCESS, EMPTY_SUBSCRIPTION } from "./actionTypes";

const INIT_STATE = {
  userPlan: "FREE",
  isUserPlanActive: false,
  freeTranslationLimit: 2,
  subscriptionCredits: 0,
};

const subscription = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_PLAN_SUCCESS:
      state = {
        ...state,
        userPlan: action.payload,
      };
      break;
    case USER_PLAN_STATUS:
      state = {
        ...state,
        isUserPlanActive: action.payload,
      };
      break;
    case USER_PLAN_FREE_TRANSLATION_LIMIT:
      state = {
        ...state,
        freeTranslationLimit: action.payload,
      };
      break;
    case SUBSCRIPTION_CREDITS:
      state = {
        ...state,
        subscriptionCredits: action.payload,
      };
      break;
    case EMPTY_SUBSCRIPTION:
      state = INIT_STATE;
      break;
    default:
      return state;
  }
  return state;
};

export default subscription;
