import React, { useState } from "react";
import checkImgSrc from "../../assets/images/sucesscheck.png";
import logo_maaia from "../../assets/images/logo/maaia_logo_1.png";

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { cancelStripeSubscription } from "../../helpers/api_helper";
import { withTranslation } from "react-i18next";
import CheckCircleMono from "../../assets/images/icons/CheckCircleMono";
import CrossCircleMono from "../../assets/images/icons/CrossCircleMono";

const StripeCancellation = (props) => {
  document.title = "Cancel Subscription | IA - Smart Assistant";

  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  let region = urlParams.get("region");
  let id = urlParams.get("id");

  const [message, setMessage] = useState({ type: null, alert: null });
  const [loading, setLoading] = useState(null);
  const onCancellationLoading = (val) => {
    setLoading(val);
  };

  const handleSubscriptionCancellation = async (stripe_region, subscription_id) => {
    onCancellationLoading(true);
    try {
      const response = await cancelStripeSubscription(stripe_region, subscription_id);
      if (response) {
        setMessage({ type: "success", alert: "Opération réussie !" });
        onCancellationLoading(false);
      } else {
        throw response;
      }
    } catch (error) {
      onCancellationLoading(false);
      setMessage({ type: "error", alert: "Echec lors de l'annulation de l'abonnement." });
    }
  };

  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="account-pages pt-5">
          <Container>
            <Row className="w-100 justify-content-center">
              <Col lg={12} md={6} xl={6}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <img
                            src={logo_maaia}
                            height={54}
                            style={{
                              marginTop: "3vh",
                            }}
                          />
                        </Link>
                      </div>
                      <h4 className="font-size-16 font-primary text-muted mt-2 text-center">{props.t("Annulation d'abonnement")}</h4>
                      <Row className="d-flex justify-content-center">
                        <Row>
                          <Col md={12}>
                            {!loading && message.type === "success" && (
                              <div
                                className="alert alert-success  font-primary d-flex align-items-center"
                                style={{
                                  flexDirection: "column",
                                }}>
                                <CheckCircleMono width={80} />
                                {props.t(message.alert)}
                              </div>
                            )}
                            {!loading && message.type === "error" && (
                              <div
                                className="alert alert-danger  font-primary d-flex align-items-center"
                                style={{
                                  flexDirection: "column",
                                }}>
                                <CrossCircleMono width={80} />
                                {props.t(message.alert)}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Row>
                      <Row className="d-flex justify-content-center">
                        <Row>
                          <Col md={12}>
                            <>
                              {message.type !== "success" && (
                                <p
                                  className=""
                                  style={{
                                    textAlign: "center",
                                  }}>
                                  {props.t("stripe_cancellation_text")}
                                </p>
                              )}
                              <div className="d-flex justify-content-center gap-3 mt-3">
                                <a
                                  className="btn waves-effect waves-light font-primary"
                                  href="/dashboard"
                                  style={{
                                    width: "100%",
                                    border: "none",
                                    borderRadius: "10px",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "29.152px",
                                    backgroundColor: "black",
                                    color: "white",
                                  }}>
                                  {props.t("Retour")}
                                </a>
                                {message.type !== "success" && (
                                  <button
                                    className="btn btn-primary waves-effect waves-light font-primary"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      borderRadius: "10px",
                                      fontSize: "16px",
                                      fontStyle: "normal",
                                      fontWeight: "500",
                                      lineHeight: "29.152px",
                                    }}
                                    onClick={() => {
                                      handleSubscriptionCancellation(region, id);
                                    }}>
                                    {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />}{" "}
                                    {props.t("Annuler l'abonnement")}
                                  </button>
                                )}
                              </div>
                            </>
                          </Col>
                        </Row>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className="simple-text font-primary">© {new Date().getFullYear()} Maaia</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(StripeCancellation);
