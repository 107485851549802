import React, { useEffect, useRef } from "react";

class EmailContentElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  set content(html) {
    this.shadowRoot.innerHTML = html;
  }
}

customElements.define("email-content-element", EmailContentElement);

const EmailContent = ({ htmlContent }) => {
  const emailRef = useRef(null);

  useEffect(() => {
    emailRef.current.content = htmlContent;
  }, [htmlContent]);

  return <email-content-element ref={emailRef}></email-content-element>;
};

export default EmailContent;
