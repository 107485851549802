import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { createEmailAccount, createEmailSessionPromise, createStatisticsFunction, updateUserPrefsPromise } from "../../../helpers/api_helper";
import { postFakeRegister, postJwtRegister } from "../../../helpers/fakebackend_helper";
import { logoutUser } from "../login/actions";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  console.log("started registerUser");

  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.registerUser, user.email, user.password);
      yield put(registerUserSuccessful(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, "/post-jwt-register", user);
      yield put(registerUserSuccessful(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeRegister, user);
      yield put(registerUserSuccessful(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "api") {
      const response = yield call(createEmailAccount, user.email, user.password);
      response["prefs"] = { owner: true };
      localStorage.setItem("authUser", response);
      localStorage.setItem("user_owner", true);
      yield put(registerUserSuccessful(response));
      const session = yield call(createEmailSessionPromise, user.email, user.password);
      if (session) {
        const prefs_response = yield call(updateUserPrefsPromise, { owner: true });
        console.log("prefs_response is", prefs_response);

        const function_response = yield call(createStatisticsFunction, prefs_response.$id);
        console.log("function_response is", function_response);

        yield put(logoutUser());
      }
    }
  } catch (error) {
    if (process.env.REACT_APP_DEFAULTAUTH === "api") {
      let message;
      switch (error.code) {
        case 500:
          message = "Erreur interne du serveur";
          break;
        case 401:
          message = "E-mail ou mot de passe incorrect";
          break;
        case 409:
          message = "Un utilisateur avec le même identifiant, email ou téléphone existe déjà";
          break;
        default:
          message = error.message;
      }
      yield put(registerUserFailed(message));
    } else {
      yield put(registerUserFailed(error));
    }
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
