import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { FETCH_USER_SUBSCRIPTION } from "./actionTypes";
import { applyUserSubscriptionSuccess, setFreeTranslationLimitState, setSubscriptionCreditsState, setUserSubscriptionState } from "./actions";
import { getCompanyInfosPromise, getStripeSubscriptionPromise } from "../../helpers/api_helper";

function* fetchSubscription({}) {
  try {
    let price_id;
    let user_plan;
    let user_plan_translation_limit = 0;
    let is_user_plan_active = false;
    let number_of_emails = 0;

    const company_infos = yield call(getCompanyInfosPromise);

    if (company_infos) {
      const exec_response = yield call(getStripeSubscriptionPromise, company_infos.plan_id, company_infos.stripe_region);
      if (exec_response.status === "failed") {
        console.error(`Error: ${exec_response.responseBody}`);
      } else {
        // L'exécution a réussi, traiter la réponse
        console.log("Execution succeeded:", JSON.parse(exec_response.responseBody));
        const responseBody = JSON.parse(exec_response.responseBody).subscription;
        if (responseBody) {
          const item = responseBody.items.data.find(
            (item) =>
              item.price.id === "price_1Pno66Jcj0cebeM8snut8tYC" ||
              item.price.id === "price_1Pno6RJcj0cebeM8Kb05R8uj" ||
              item.price.id === "price_1Pno51B6CkBqvsv3xGNqsD85" ||
              item.price.id === "price_1Pno5RB6CkBqvsv30aS5IQNH",
          );

          number_of_emails =
            responseBody.items.data.find(
              (item) => item.price.id === "price_1Po5d2Jcj0cebeM8fdeCqALq" || item.price.id === "price_1Po5t4B6CkBqvsv3j1nOcbDE",
            ).quantity * 100;
          price_id = item.price.id;
          is_user_plan_active = responseBody.status === "active" ? true : false;
          console.log({ user_plan_price_id: price_id, is_user_plan_active, number_of_emails });
        } else {
          console.log("Erreur inattendue");
        }
      }

      switch (price_id) {
        case "price_1Pno66Jcj0cebeM8snut8tYC":
          user_plan = "MAAIA_PLAN_INFINITY";
          user_plan_translation_limit = 100;
          break;
        case "price_1Pno51B6CkBqvsv3xGNqsD85":
          user_plan = "MAAIA_PLAN_INFINITY";
          user_plan_translation_limit = 100;
          break;
        case "price_1Pno6RJcj0cebeM8Kb05R8uj":
          user_plan = "MAAIA_PLAN_ESSENTIAL";
          user_plan_translation_limit = 20;
          break;
        case "price_1Pno5RB6CkBqvsv30aS5IQNH":
          user_plan = "MAAIA_PLAN_ESSENTIAL";
          user_plan_translation_limit = 20;
          break;
        default:
          user_plan = "FREE";
          break;
      }

      yield put(applyUserSubscriptionSuccess(user_plan));
      yield put(setUserSubscriptionState(is_user_plan_active));
      yield put(setFreeTranslationLimitState(user_plan_translation_limit));
      yield put(setSubscriptionCreditsState(number_of_emails));
      console.log({ user_plan, user_plan_translation_limit, number_of_emails });
    } else {
      console.log("Erreur inattendue");
    }
  } catch (error) {
    let message;
    switch (error.code) {
      case 500:
        message = "Erreur interne du serveur";
        break;
      default:
        message = "Erreur inattendue";
    }
    console.log({ error_message: message });
  }
}

function* subscriptionSaga() {
  yield takeEvery(FETCH_USER_SUBSCRIPTION, fetchSubscription);
}

export default subscriptionSaga;
