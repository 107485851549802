const aiBehaviorData = [
  {
    key: "reply_to_thank_messages",
    title: "Répondre aux remerciements",
    description: "ai_behavior_description_1",
  },
  {
    key: "move_treated_emails",
    title: "Move treated messages",
    description: "move_treated_msg_description",
  },
];
export default aiBehaviorData;
