import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Row } from "reactstrap";
import { UpgradeAgent, fetchAgentInfos, fetchAgentLatestVersion } from "../../helpers/api_helper";
import Icons from "../../static/icons";
function AgentCard(props) {
  // const [agentInfos, setAgentInfos] = useState();
  const [agentLatestVersion, setAgentLatestVersion] = useState();
  const [agentLatestVersionName, setagentLatestVersionName] = useState();
  const {
    index,
    agent,
    company_id,
    deleteAgentLoading,
    sendLanguageFlag,
    agentStatusChangeActions,
    togUpdateAgentModal,
    setAgentModalInfo,
    setTimesByDay,
    deleteAgentActions,
  } = props;

  // const isAgentDisabled = props.activeAgents.length === props.agentQty && !props.activeAgents.includes(agent.id);
  const isAgentDisabled = false;

  // async function getAgentInfos() {
  //   try {
  //     const response = await fetchAgentInfos(company_id, agent.id);
  //     setAgentInfos(response.agent_data);
  //     if (response.agent_data.State.Running) {
  //       props.setActiveAgents(props.activeAgents.includes(agent.id) ? props.activeAgents : [...props.activeAgents, agent.id]);
  //     }
  //     console.log({ fetchAgentInfos: response });
  //   } catch (error) {
  //     console.log("Error while fecthing agent infos", error);
  //   }
  // }

  async function getAgentLatestVersion() {
    try {
      const response = await fetchAgentLatestVersion(company_id, agent.id);
      setAgentLatestVersion(response.version_code);
      setagentLatestVersionName(response.version_name);
      console.log({ agentLatestVersion: response });
    } catch (error) {
      console.log("Error while fecthing latest version", error);
    }
  }

  async function handleAgentupgrade() {
    try {
      const response = await UpgradeAgent(company_id, agent.id);
      console.log({ AgentUpgraded: response });
      //  getAgentInfos();
      getAgentLatestVersion();
    } catch (error) {
      console.log("Error while upgrading agent version", error);
    }
  }

  useEffect(() => {
    //   getAgentInfos();
    getAgentLatestVersion();
  }, [agent]);

  return (
    <Col md={6} xxl={4} key={index}>
      <Card
        className="agent-card"
        style={{
          borderLeft: "5px solid #563BFF",
          borderRadius: "15px",
          boxShadow: "0px 1px 1px 1px rgba(53, 22, 252, 0.1)",
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10x",
          width: "100%",
        }}>
        <CardBody className="p-1">
          <Row>
            {/* <div style={{ marginLeft: "25px" }}>
                <input
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "6px",
                    border: "1.5px solid #D9D9D9",
                  }}
                  className="form-check-input"
                  type="checkbox"
                  value={`option${index + 1}`}
                />
              </div> */}
            <div className="d-flex col-auto ps-3" style={{ alignItems: "center", justifyContent: "center", padding: "0px", flex: "0" }}>
              <div
                className="font-primary"
                style={{
                  fontSize: "20px",
                  backgroundColor: "rgb(244, 244, 244)",
                  color: "black",
                  borderRadius: "100px",
                  fontWeight: "normal",
                  height: "50px",
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {agent.agent_name
                  .split(" ")
                  .map((part) => part.charAt(0).toUpperCase())
                  .join("")}
              </div>
            </div>
            <Col className="ps-2">
              <div className="d-flex flex-wrap gap-2">
                <div
                  className="agent-name font-primary"
                  style={{
                    width: "fit-content",
                    color: "#1E222C",
                    fontSize: "16px",
                    fontStyle: "normal",
                    position: "relative",
                    fontWeight: "normal",
                  }}>
                  {agent.agent_name}
                </div>
                {/* {agentInfos && ( */}
                <span
                  className={`font-primary  ${agent.active ? "badge badge-soft-success" : "badge badge-soft-danger"}`}
                  style={{
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    height: "fit-content",
                    padding: "5px",
                    marginLeft: "auto",
                  }}>
                  {/* {agentInfos.State.Running ? props.t("Actif") : props.t("Inactif")} */}
                  {agent.active ? props.t("Actif") : props.t("Inactif")}
                </span>
                {/* ) */}
              </div>
              <div className="d-flex align-items-center" style={{}}>
                <div>
                  {agent.agent_language &&
                    agent.agent_language.map((lang, index) => (
                      <img
                        style={{ paddingRight: "4px", paddingLeft: "2px" }}
                        height={10}
                        alt={`flag-${lang.name}`}
                        src={sendLanguageFlag(lang.code)}
                      />
                    ))}
                </div>
                {!deleteAgentLoading[agent.id] ? (
                  <div className="d-flex ms-auto">
                    <Col className="col-auto">
                      <div className="d-flex align-items-center">
                        <button
                          onClick={() => {
                            agentStatusChangeActions(index, agent.active);
                          }}
                          disabled={isAgentDisabled}
                          style={{
                            border: "none",
                            backgroundColor: "inherit",
                            borderRadius: "8px",
                            //  color: isAgentDisabled ? "gray" : agentInfos.State.Running ? "red" : "#0fdf0f",
                            color: isAgentDisabled ? "gray" : agent.active ? "red" : "#0fdf0f",
                            cursor: isAgentDisabled ? "default" : "pointer",
                          }}>
                          {/* {agentInfos.State.Running ? Icons.pause_icon : Icons.play} */}
                          {agent.active ? Icons.pause_icon : Icons.play}
                        </button>
                        {/* {agentInfos ? (
                          <button
                            onClick={() => {
                              agentStatusChangeActions(index, agentInfos.State.Running);
                            }}
                            disabled={isAgentDisabled}
                            style={{
                              border: "none",
                              backgroundColor: "inherit",
                              borderRadius: "8px",
                              color: isAgentDisabled ? "gray" : agentInfos.State.Running ? "red" : "#0fdf0f",
                              cursor: isAgentDisabled ? "default" : "pointer",
                            }}>
                            {agentInfos.State.Running ? Icons.pause_icon : Icons.play}
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              agentStatusChangeActions(index, false);
                            }}
                            disabled={isAgentDisabled}
                            style={{
                              border: "none",
                              backgroundColor: "inherit",
                              borderRadius: "8px",
                              color: isAgentDisabled ? "gray" : "#0fdf0f",
                              cursor: isAgentDisabled ? "default" : "pointer",
                            }}>
                            {Icons.play}
                          </button>
                        )} */}
                      </div>
                    </Col>
                    <Col className="col-auto">
                      <div className="d-flex align-items-center">
                        <button
                          onClick={() => {
                            togUpdateAgentModal();
                            setAgentModalInfo(agent);
                            setTimesByDay(agent.agent_work_times);
                            console.log("agent.agent_work_times is", agent.agent_work_times);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "inherit",
                            borderRadius: "8px",
                          }}>
                          {Icons.pen}
                        </button>
                      </div>
                    </Col>
                    {/* <Col className="col-auto">
                      <div className="d-flex align-items-center">
                        <button
                          className=""
                          onClick={() => {
                            deleteAgentActions(agent.id);
                          }}
                          style={{
                            border: "none",
                            backgroundColor: "inherit",
                            borderRadius: "8px",
                          }}>
                          {Icons.trash}
                        </button>
                        {agentInfos ? (
                          !agentInfos.State.Running && (
                            <button
                              className=""
                              onClick={() => {
                                deleteAgentActions(agent.id);
                              }}
                              style={{
                                border: "none",
                                backgroundColor: "inherit",
                                borderRadius: "8px",
                              }}>
                              {Icons.trash}
                            </button>
                          )
                        ) : (
                          <button
                            className=""
                            onClick={() => {
                              deleteAgentActions(agent.id);
                            }}
                            style={{
                              border: "none",
                              backgroundColor: "inherit",
                              borderRadius: "8px",
                            }}>
                            {Icons.trash}
                          </button>
                        )}
                      </div>
                    </Col> */}
                  </div>
                ) : (
                  <span className="ms-auto spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
                )}
              </div>

              <div
                className=" d-flex flex-wrap align-items-center"
                style={{
                  height: "18px",
                }}>
                <div
                  style={{
                    fontSize: "8px",
                    fontStyle: "normal",
                    fontWeight: "100",
                    color: "grey",
                    //paddingBottom: "10px",
                  }}>
                  {/* {agentInfos ? props.t("Version") + " " + agentInfos.Config.version_name : props.t("Version") + " " + agentLatestVersionName} */}
                </div>
                {/* {agentInfos && agentLatestVersion && agentLatestVersion > agentInfos.Config.app_version && (
                  <div
                    className={`font-primary  badge ms-auto`}
                    style={{
                      fontSize: "8px",
                      fontStyle: "normal",
                      height: "fit-content",
                      padding: "5px",
                      fontWeight: "400",
                      backgroundColor: "#dfdaff",
                      color: "#563bff",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    onClick={() => {
                      handleAgentupgrade();
                    }}>
                    Nouvelle version disponible
                    <span
                      className="pulse"
                      style={{
                        position: "absolute",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        boxShadow: "0px 0px 1px 1px #0000001a",
                        backgroundColor: "#563bff",
                        top: "-5px",
                      }}></span>
                  </div>
                )} */}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}

export default withTranslation()(AgentCard);
