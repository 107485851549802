import React, { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import NoDesktopTempUI from "../Pages/Utility/NoDesktopTempUI";

const AuthProtected = (props) => {
  /*
    redirect is un-auth access protected routes via url
    */
  const user_string = localStorage.getItem("user");
  const user = user_string ? JSON.parse(user_string) : undefined;
  const user_auth_string = localStorage.getItem("authUser");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 1200);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  if (!user_auth_string) {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />;
  } else if (
    user_string &&
    user &&
    user.prefs &&
    user.prefs["owner"] &&
    (user.prefs["owner"] === "true" || user.prefs["owner"] === true) &&
    !user.prefs["finished_onbording"]
  ) {
    return <Navigate to={{ pathname: "/setup" }} />;
  }

  return <> {isLargeScreen ? props.children : <NoDesktopTempUI />}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
