import React from "react";

function PlusRecOutlined({ width, height, className, style }) {
  return (
    <svg width={width} height={height} className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 9.5V9V9C22 8.07116 22 7.60674 21.9487 7.21684C21.5942 4.52444 19.4756 2.40579 16.7832 2.05133C16.3933 2 15.9288 2 15 2H11.6C8.23969 2 6.55953 2 5.27606 2.65396C4.14708 3.2292 3.2292 4.14708 2.65396 5.27606C2 6.55953 2 8.23969 2 11.6V12.4C2 15.7603 2 17.4405 2.65396 18.7239C3.2292 19.8529 4.14708 20.7708 5.27606 21.346C6.55953 22 8.23969 22 11.6 22H15C15.9288 22 16.3933 22 16.7832 21.9487C19.4756 21.5942 21.5942 19.4756 21.9487 16.7832C22 16.3933 22 15.9288 22 15V15V14.5M8 12H12M12 12H16M12 12V8M12 12V16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PlusRecOutlined;
