import React from "react";

function PowerOutlined(props) {
  const { width, height, className } = props;
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2V12M16 4.93555C18.9634 6.40825 21 9.46631 21 13C21 14.0519 20.8195 15.0617 20.4879 16M8 4.93555C5.03656 6.40825 3 9.46631 3 13C3 17.9706 7.02944 22 12 22C14.3051 22 16.4077 21.1335 18 19.7083"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default PowerOutlined;
