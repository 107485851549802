import React, { useState, useEffect, componentDidMount, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Col, Row } from "reactstrap";
import logo_maaia from "../../assets/images/maaia-logo-violet.png";
import received_mails from "../../assets/images/received_mails.png";
import { withTranslation } from "react-i18next";

const ReceiveMail = (props) => {
  let DashedLineData = null;
  const [mailReceive, setMailReceive] = useState();
  const [loading, setLoading] = useState(true);
  const dataMap = new Map();
  const theMessages = props.messages;
  const theStatistics = props.statistics;
  const [formattedDataList, setformattedDataList] = useState({
    series: [
      {
        name: "Nombre de messages",
        data: [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      colors: ["#82c2bf"],
      xaxis: {
        type: "datetime",
        categories: [],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    },
  });

  // Fonction pour agréger le nombre de messages par jour
  const aggregateMessagesByDay = (theStatistics) => {
    const aggregatedData = {};

    theStatistics.forEach((statistic) => {
      const dayKey = statistic.$updatedAt;

      aggregatedData[dayKey] = {
        date: dayKey,
        count: statistic.mail_count,
      };
    });

    return Object.values(aggregatedData);
  };

  const handleDateChange = (theStatistics) => {
    var data = theStatistics;
    // Agréger le nombre de messages par jour
    const aggregatedData = aggregateMessagesByDay(data);

    // Mettre à jour le state avec les données agrégées
    setformattedDataList((prevData) => ({
      ...prevData,
      series: [
        {
          name: "Nombre de messages",
          data: aggregatedData.map((dataPoint) => ({
            x: dataPoint.date,
            y: dataPoint.count,
          })),
        },
      ],
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: aggregatedData.map((dataPoint) => dataPoint.date),
        },
      },
    }));
    // setformattedDataList(finalChartData);

    setLoading(false);
  };

  useEffect(() => {
    handleDateChange(theStatistics);
    console.log("The stats", theStatistics);
  }, [theStatistics]);
  return (
    <React.Fragment>
      {formattedDataList && formattedDataList.series[0].data.length > 0 ? (
        <ReactApexChart
          options={formattedDataList.options}
          series={formattedDataList.series}
          type="area"
          height="235"
          className="apex-charts font-primary"
        />
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundImage: "url(" + received_mails + ")",
            height: "100%",
            width: "100%",
            backgroundSize: "138%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}>
          <div className="font-primary font-size-22 color-black mt-5">{props.t("En attente de données")}</div>
          <img src={logo_maaia} height={40} />
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(ReceiveMail);
