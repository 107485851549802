import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "../components/Common/withRouter";
import NoDesktopTempUI from "../Pages/Utility/NoDesktopTempUI";
import { Navigate, Route } from "react-router-dom";

const NonAuthLayout = (props) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);
  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 1200);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  const user_string = localStorage.getItem("user");
  const user = user_string ? JSON.parse(user_string) : undefined;
  const user_auth_string = localStorage.getItem("authUser");

  // console.log({ props });

  if (props?.router?.location?.pathname === "/setup") {
    if (!user_auth_string) {
      return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />;
    }
  }
  if (props?.router?.location?.pathname === "/login" && user_auth_string) {
    return <Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} />;
  }

  return isLargeScreen || props?.router?.location?.pathname === "/checkout" ? <React.Fragment>{props.children}</React.Fragment> : <NoDesktopTempUI />;
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
