import React from "react";

function DollarDuocolor(props) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M12 2L12 22" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6 16C6 18.2091 7.79086 20 10 20H14.5132C16.4389 20 18 18.4389 18 16.5132C18 15.0123 17.0396 13.6799 15.6158 13.2053L8.3842 10.7947C6.96038 10.3201 6 8.98767 6 7.48683C6 5.56111 7.56111 4 9.48683 4H14C16.2091 4 18 5.79086 18 8"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DollarDuocolor;
