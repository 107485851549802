import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { FETCH_CONSUMED_CREDIT } from "./actionTypes";
import { applyConsumedCredit } from "./actions";

//Include Both Helper File with needed methods
import { getCompanyInfosPromise } from "../../helpers/api_helper";

// Is user register successfull then direct plot user in redux.
function* getCompanyData() {
  console.log("started getCompanyData fetch");

  try {
    const data = yield call(getCompanyInfosPromise);
    if (data) {
      console.log("Redux Company data fetched successfully", data);
      yield put(applyConsumedCredit(data.consumed_email_count, data));
    } else {
      console.log("Redux Company data fetch failed", data);
    }
  } catch (error) {}
}

export function* watchGetCompanyData() {
  yield takeEvery(FETCH_CONSUMED_CREDIT, getCompanyData);
}

function* generalDataSaga() {
  yield all([fork(watchGetCompanyData)]);
}

export default generalDataSaga;
