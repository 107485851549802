import React from "react";

function SearchOutlined({ width, height, className, style }) {
  return (
    <svg width={width} height={height} className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 21L18.4953 18.4953C18.1033 18.1033 17.9073 17.9073 17.7092 17.8311C17.5121 17.7553 17.3684 17.7425 17.161 17.7825C16.9527 17.8226 16.6814 18.0117 16.1387 18.3897C14.6817 19.4048 12.9104 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 12.2414 19.7487 13.4241 19.2941 14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SearchOutlined;
