import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
  BasicTable,
  ScrollVertical,
  ScrollHorizontal,
  AlternativePagination,
  FixedHeaderDatatables,
  ModalDataDatatables,
  AjaxDatatables,
} from "./datatableCom";

const DataTables = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tables" breadcrumbItem="Datatables" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Basic Datatables</h5>
                </CardHeader>
                <CardBody>
                  <BasicTable />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Scroll - Vertical</h5>
                </CardHeader>
                <CardBody>
                  <ScrollVertical />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Scroll - Horizontal</h5>
                </CardHeader>
                <CardBody>
                  <ScrollHorizontal style={{ width: "100%" }} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Alternative Pagination</h5>
                </CardHeader>
                <CardBody>
                  <AlternativePagination />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Fixed Header Datatables</h5>
                </CardHeader>
                <CardBody>
                  <FixedHeaderDatatables />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Modal Data Datatables</h5>
                </CardHeader>
                <CardBody>
                  <ModalDataDatatables />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Ajax Datatables</h5>
                </CardHeader>
                <CardBody>
                  <AjaxDatatables />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DataTables;
