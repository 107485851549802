import React, { useEffect, useState } from "react";

import checkImgSrc from "../../assets/images/sucesscheck.png";
import logo_maaia from "../../assets/images/logo/maaia_logo_1.png";

import { Container, Row, Col, Card, CardBody, FormGroup, Label, InputGroup, Input, InputGroupText, FormFeedback } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { Account, Client } from "appwrite";
import { withTranslation } from "react-i18next";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import PasswordStrengthBar from "react-password-strength-bar";

const ResetPassword = (props) => {
  document.title = "Reset Password | IA - Smart Assistant";
  const { search } = useLocation();

  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState({ type: null, alert: null });
  const [loading, setLoading] = useState(null);
  const [urlParams, setUrlParams] = useState(null);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const onPwResetLoading = (val) => {
    setLoading(val);
  };
  const togglePasswordVisibility = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  const handlePwReset = async (newPassword) => {
    onPwResetLoading(true);

    const client = new Client().setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const account = new Account(client);

    const secret = urlParams.get("secret");
    const userId = urlParams.get("userId");

    const promise = account.updateRecovery(userId, secret, newPassword);

    await promise.then(
      function (response) {
        onPwResetLoading(false);
        setMessage({ type: "success", alert: "" });
        console.log(response);
      },
      function (error) {
        onPwResetLoading(false);
        setMessage({ type: "error", alert: getErrorMessage(error.type) });
        console.log(error);
      }
    );
  };

  const getErrorMessage = (type) => {
    switch (type) {
      case "user_invalid_token":
        return "user_invalid_token_text";
      default:
        return "Echec lors de la modification du mot de passe.";
    }
  };

  const [passwordScore, setPasswordScore] = useState(0);

  const validation = useFormik({
    initialValues: {
      password: "",
      password_confirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Veuillez entrer votre nouveau mot de passe")
        .min(8, "Veuillez entrer un mot de passe d'au moins 8 charactères")
        .test("password-strength", "Le mot de passe est trop faible", function (value) {
          return passwordScore >= 1;
        }),
      password_confirm: Yup.string()
        .required("Veuillez confirmer votre mot de passe")
        .test("passwords-match", "Les mots de passe ne correspondent pas", function (value) {
          return this.parent.password === value;
        }),
    }),
    onSubmit: (values) => {
      handlePwReset(values.password);
    },
  });
  const svgs = {
    eye_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=""
        style={{ width: "24px" }}>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
        />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </svg>
    ),
    eye_slash_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=""
        style={{ width: "24px" }}>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
        />
      </svg>
    ),
  };

  useEffect(() => {
    console.log(search);
    if (search) {
      setUrlParams(new URLSearchParams(search));
    } else {
      setMessage({ type: "error", alert: "Echec lors de la vérification." });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="account-pages pt-5">
          <Container>
            <Row className="w-100 justify-content-center">
              <Col lg={12} md={6} xl={6}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <img
                            src={logo_maaia}
                            height={54}
                            style={{
                              marginTop: "3vh",
                            }}
                          />
                        </Link>
                      </div>
                      <h4 className="font-size-16 text-muted mt-2 text-center font-primary">{props.t("Réinitialisation de votre mot de passe")}</h4>
                      <form className="form-horizontal" action="#">
                        {urlParams ? (
                          <>
                            <Row>
                              <Col md={12}>
                                {!loading && message.type === "success" && (
                                  <div className="alert alert-success text-center font-primary">
                                    {props.t("Votre mot de passe a été réinitialisé avec succès !")}
                                  </div>
                                )}
                                {!loading && message.type === "error" && (
                                  <div className="alert alert-danger text-center font-primary">{props.t(message.alert)}</div>
                                )}
                              </Col>
                              <Col className="d-flex justify-content-center">
                                <img style={{ display: message.type === "success" ? "block" : "none" }} src={checkImgSrc} alt="" />
                              </Col>
                            </Row>
                            {message.type !== "success" && (
                              <Row>
                                <Col md={12}>
                                  <div className="alert alert-warning alert-dismissible text-center font-primary">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    {props.t("Entrez votre")} <b>{props.t("nouveau mot de passe")}</b> {props.t("pour la réinitialisation.")}
                                  </div>
                                  <div className="mt-2">
                                    <FormGroup>
                                      <Label htmlFor="basicpill-recoverpw-input22" className="form-label font-primary">
                                        {props.t("Mot de passe")}
                                      </Label>
                                      <InputGroup className="form-control-recoverpw">
                                        <Input
                                          className="form-control form-control-recoverpw"
                                          id="basicpill-recoverpw-input22"
                                          name="password"
                                          placeholder={props.t("Entrez votre mot de passe")}
                                          type={isPasswordHidden ? "password" : "text"}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.password || ""}
                                          invalid={validation.touched.password && validation.errors.password ? true : false}
                                        />
                                        <InputGroupText
                                          style={{
                                            backgroundColor: "transparent",
                                            border: "none",
                                            padding: "0",
                                          }}>
                                          <button
                                            className="btn h-100"
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            style={{
                                              borderTopLeftRadius: "0",
                                              borderBottomLeftRadius: "0",
                                            }}>
                                            {isPasswordHidden ? svgs.eye_icon : svgs.eye_slash_icon}
                                          </button>
                                        </InputGroupText>
                                        {validation.touched.password && validation.errors.password ? (
                                          <FormFeedback type="invalid">
                                            <div style={{ fontSize: "14px" }} className="mb-3">
                                              {props.t(validation.errors.password)}
                                            </div>
                                          </FormFeedback>
                                        ) : null}
                                      </InputGroup>
                                    </FormGroup>
                                  </div>
                                  <div style={{ marginTop: validation.touched.password && validation.errors.password ? "32px" : "0px" }}>
                                    <PasswordStrengthBar
                                      password={validation.values.password}
                                      onChangeScore={(score) => setPasswordScore(score)}
                                      scoreWords={[props.t("weak"), props.t("weak"), props.t("okay"), props.t("good"), props.t("strong")]}
                                      shortScoreWord={props.t("too short")}
                                    />
                                  </div>
                                  <div className="mt-4 mb-5">
                                    <FormGroup>
                                      <Label htmlFor="basicpill-recoverpw-input22" className="form-label font-primary">
                                        {props.t("Confirmer Mot de Passe")}
                                      </Label>
                                      <InputGroup className="form-control-recoverpw">
                                        <Input
                                          name="password_confirm"
                                          className="form-control form-control-recoverpw"
                                          placeholder={props.t("Confirmer Mot de Passe")}
                                          type={isPasswordHidden ? "password" : "text"}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.password_confirm || ""}
                                          invalid={validation.touched.password_confirm && validation.errors.password_confirm ? true : false}
                                        />
                                        <InputGroupText
                                          style={{
                                            backgroundColor: "transparent",
                                            border: "none",
                                            padding: "0",
                                          }}>
                                          <button
                                            className="btn h-100 form-control"
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            style={{
                                              borderTopLeftRadius: "0",
                                              borderBottomLeftRadius: "0",
                                            }}>
                                            {isPasswordHidden ? svgs.eye_icon : svgs.eye_slash_icon}
                                          </button>
                                        </InputGroupText>
                                        {validation.touched.password_confirm && validation.errors.password_confirm ? (
                                          <FormFeedback type="invalid">
                                            <div className="font-primary">{props.t(validation.errors.password_confirm)}</div>
                                          </FormFeedback>
                                        ) : null}
                                      </InputGroup>
                                    </FormGroup>
                                  </div>

                                  <div className="d-grid mt-4">
                                    <button
                                      type="button"
                                      className="btn btn-primary waves-effect waves-light font-primary"
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        borderRadius: "10px",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "29.152px",
                                      }}
                                      onClick={() => {
                                        validation.handleSubmit();
                                      }}>
                                      {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />}{" "}
                                      {props.t("Modifier")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </>
                        ) : (
                          <div className="alert alert-danger text-center font-primary mt-4">{props.t("Une erreur inatendue s'est produite.")}</div>
                        )}
                      </form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className="simple-text font-primary">
                    <Link to="/login" className="fw-medium text-primary simple-text">
                      {props.t("Connexion")}
                    </Link>
                  </p>
                  <p className="simple-text font-primary">© {new Date().getFullYear()} MAAIA - Smart Assistant</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(ResetPassword);
