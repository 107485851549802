import React, { useEffect, useState } from "react";
import Slider from "react-rangeslider";

const CustomCalculator = () => {
  const [numberOfEmailsPacks, setNumberOfEmailsPacks] = useState(1);
  return (
    <div className="mx-auto d-flex" style={{ flexDirection: "column", width: "94%" }}>
      <div className="mx-auto mt-4" style={{ width: "50%" }}>
        <h4>Number of Email per Month</h4>
        <div className="w-100 d-flex gap-2" style={{ flexDirection: "column" }}>
          <span className="font-size-16" style={{ fontWeight: "bold" }}>{`${numberOfEmailsPacks * 100} emails`}</span>
          <Slider
            min={1}
            max={100}
            tooltip={false}
            value={numberOfEmailsPacks}
            className="my-0 w-100 custom_range_slider"
            onChange={(val) => {
              setNumberOfEmailsPacks(val);
            }}
          />
          <div className="d-flex justify-content-between w-100">
            <span className="float-left simple-text font-primary" style={{ userSelect: "none" }}>
              0
            </span>
            <span className="float-right simple-text font-primary" style={{ userSelect: "none" }}>
              10.000
            </span>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center mt-4 gap-4" style={{ flex: "1" }}>
        <div className="card_essentials">
          <h3 className="text-center mb-5" style={{ fontWeight: "900", marginTop: "20px", color: "rgb(40, 0, 89)" }}>
            ESSENTIAL
          </h3>
          <div className="cont-formula mt-3 mb-2" style={{ fontSize: "3rem", color: "rgb(35, 0, 87)" }}>
            <div className="prefix-field" style={{ fontWeight: "600" }}>
              $
            </div>
            <div className="rendered-formula" prefix="$" suffix="/month" hide-decimals="true">
              {9 + numberOfEmailsPacks * 100 * 0.25}
            </div>
            <div className="suffix-field" style={{ fontSize: "1.25rem", fontWeight: "600", marginTop: "25px" }}>
              /month
            </div>
          </div>
          <div className="d-flex font-size-16">
            <div className="" style={{ fontWeight: "600" }}>
              25.0
            </div>
            <div className="d-flex" style={{}}>
              <div style={{ fontStyle: "italic" }}>¢</div>
              <div>/email</div>
            </div>
          </div>
          {/* <div className="my-5">
            <div className="suffix-field" style={{ fontSize: "1.25rem", fontWeight: "600" }}>
              What's include ?
            </div>
          </div> */}

          <div className="py-4 d-flex">
            <a
              tabindex="0"
              className="js-calltoaction ic-button-link w-100 mb-4"
              href={`/checkout?e=${numberOfEmailsPacks}&p=essential`}
              style={{ color: "white", backgroundColor: "black", borderRadius: "8px", fontWeight: "600" }}>
              Choose this Plan
            </a>
          </div>
        </div>
        <div className="card_infinity">
          <h3 className="text-center mb-5" style={{ fontWeight: "900", marginTop: "20px", color: "white" }}>
            INFINITY
          </h3>
          <div className="cont-formula mt-3 mb-2" style={{ fontSize: "3rem", color: "white" }}>
            <div className="prefix-field" style={{ fontWeight: "600" }}>
              $
            </div>
            <div className="rendered-formula" prefix="$" suffix="/month" hide-decimals="true">
              {19 + numberOfEmailsPacks * 100 * 0.25}
            </div>
            <div className="suffix-field" style={{ fontSize: "1.25rem", fontWeight: "600", marginTop: "25px" }}>
              /month
            </div>
          </div>
          <div className="d-flex font-size-16" style={{ color: "white" }}>
            <div className="" style={{ fontWeight: "600" }}>
              25.0
            </div>
            <div className="d-flex" style={{}}>
              <div style={{ fontStyle: "italic" }}>¢</div>
              <div>/email</div>
            </div>
          </div>
          {/* <div className="my-5">
            <div className="suffix-field" style={{ fontSize: "1.25rem", fontWeight: "600" }}>
              What's include ?
            </div>
          </div> */}

          <div className="py-4 d-flex">
            <a
              tabindex="0"
              className="js-calltoaction ic-button-link w-100 mb-4"
              href={`/checkout?e=${numberOfEmailsPacks}&p=infinity`}
              style={{ color: "rgb(40, 0, 89)", backgroundColor: "rgb(255, 213, 0)", borderRadius: "8px", fontWeight: "600" }}>
              Choose this Plan
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCalculator;
