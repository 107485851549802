import React from "react";

function ArrowLeftOutlined({ width, height, className, style }) {
  return (
    <svg width={width} height={height} className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 5L6 9M10 18.9999L3 11.9999H21" stroke="#2F384C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export default ArrowLeftOutlined;
