import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

//
import BellOutlined from "../../../assets/images/icons/BellOutlined";
import ShoppingCartOutlined from "../../../assets/images/icons/ShoppingCartOutlined";
import AlertTriangleGestalt from "../../../assets/images/icons/AlertTriangleGestalt";
import WrenchGestalt from "../../../assets/images/icons/WrenchGestalt";
import { Client, Databases, Query } from "appwrite";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState(undefined);
  async function getNotifications() {
    const client = new Client();
    const databases = new Databases(client, "default");

    client.setEndpoint("https://api.shopybase.com/v1").setProject("ai_project_dev");

    const promise = databases.listDocuments("default", "notifications", [Query.equal("company_id", localStorage.getItem("companyId"))]);

    return promise.then(
      async function (response) {
        setNotifications(response.documents);
        return response.documents;
      },
      function (error) {
        console.log("Error listing notifications ", error);
        return null;
      }
    );
  }
  const getNotificationsIcons = (key) => {
    switch (key) {
      case "exhausted_credit":
        return <AlertTriangleGestalt width={18} height={18} />;
      case "maintenance":
        return <WrenchGestalt width={18} height={18} />;
      default:
        break;
    }
  };
  const getNotificationsColors = (key) => {
    switch (key) {
      case "exhausted_credit":
        return "#ff3d60";
      case "maintenance":
        return "rgb(86, 59, 255)";
      default:
        break;
    }
  };
  function formatDate(dateString) {
    const options = { day: "2-digit", month: "short", year: "2-digit", hour: "2-digit", minute: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString(props.i18n.language + "-" + props.i18n.language.toUpperCase(), options);
  }
  useEffect(() => {
    getNotifications();
  }, [localStorage.getItem("companyId")]);
  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="dropdown d-inline-block" tag="li">
        <DropdownToggle
          className="header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
          style={{
            padding: "10px",
            borderRadius: "100%",
            background: "#f2f1ff",
            color: "#FFA412",
          }}>
          <BellOutlined width="22" height="21" strokeWidth="2">
            <span className="noti-dot"></span>
          </BellOutlined>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-primary">{props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto font-primary">
                <Link to="/#" className="small font-primary">
                  {props.t("View All")}
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications &&
              notifications.map((notification) => (
                <Link key={notification.$id} to="" className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar-xs">
                        <span
                          className="avatar-title rounded-circle font-size-16"
                          style={{ backgroundColor: getNotificationsColors(notification.type) }}>
                          {getNotificationsIcons(notification.type)}
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h6
                        className="mb-1 font-primary text-muted"
                        style={{
                          maxWidth: "100%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}>
                        {notification.title}
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p
                          className="mb-1 font-primary teaser"
                          style={{
                            maxWidth: "100%",
                          }}>
                          {notification.message}
                        </p>
                        <p className="mb-0 font-primary">
                          <i className="mdi mdi-clock-outline"></i> {formatDate(notification.$createdAt)}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="#">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
